import { spawn } from 'redux-saga/effects';
import teacherSaga from '../containers/Teacher/sagas';
import coursesSaga from '../containers/Courses/sagas';
import lessonsSaga from '../containers/Lessons/sagas';
import taskSaga from '../containers/Tasks/sagas';
import messagesSaga from '../containers/Messages/sagas';
import ordersSaga from '../containers/Orders/sagas';
import tokenSaga from '../containers/Tokens/sagas'

export default function* rootSaga() {
    yield spawn(teacherSaga);
    yield spawn(coursesSaga);
    yield spawn(lessonsSaga);
    yield spawn(taskSaga);
    yield spawn(messagesSaga);
    yield spawn(ordersSaga);
    yield spawn(tokenSaga);
  }