var urlName = window.location.host;
var splitUrl = urlName.split('.')
console.log('sysy',getSubdomain(window.location.hostname));
console.log('splitUrl',splitUrl);
export const subDomain = splitUrl[1] || '';

function getSubdomain(hostname) {
    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts[0],'').slice(0, -1);
}
