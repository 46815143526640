import { 
  GET_TOKEN,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_ERROR,
} from './constants';
import { sessionReducer }  from '../../SessionContext';

const initialState = {
  tokenData: '',
  tokenError:'',
};
const stateOb = sessionReducer ? JSON.parse(sessionReducer) : '';

export function tokenReducer(state = stateOb.tokenReducer || initialState, action) {
  switch (action.type) {
  case  GET_TOKEN:
      return {
        ...state,
     };
	case  GET_TOKEN_SUCCESS:
      return {
        ...state,
        tokenData: action.data
     };
	case GET_TOKEN_ERROR:
      return {
        ...state,
        tokenError: 'Some error occured, please contact our customer spport'
     };
  default:
      return state;
  }
}
