import React, { Component } from 'react';
import NoData from '../NoData';
import photoshop from '../../assets/images/photoshop.png';
import crossicn from '../../assets/images/crossicn.png';
import { FileUploadModal } from '../FileUploadModal';
import TaskViewModal from '../TaskViewModal';


const TaskDetailsItems=(props)=>{
  const taskTitle = props.tasks ? props.tasks.taskTitle : '';
  const taskId = props.tasks ? props.tasks.taskId : '';
  const studentName = props.tasks ? props.tasks.studentName : '';
  const message = props.tasks ? props.tasks.message : '';
  const submittedDate = props.tasks ? props.tasks.submittedDate : '';
  const timeFormat = new Date(submittedDate);
  const defaultTime = new Date(null);
  var displayTime = '';
  if((defaultTime.getTime())!==(timeFormat.getTime())) {
    displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
  }
  const taskShow=()=>{
   const modalDivClose = document.getElementById('taskView');
   modalDivClose.style.display="block";
  }
  return(
    <tr>
    <td>
          {taskTitle}
    </td>
    <td>
      <p class="mb-0">{studentName}</p>
    </td>
    <td>
       <p class="mb-0">{displayTime}</p>
    </td>
    <td>
       {message}
    </td>
    <td>
       <TaskViewModal tasks={props.tasks}/>
       <FileUploadModal taskId={taskId} origin={0} tasksDataUpload={props.tasksDataUpload}/>
   </td>
 </tr>
  )
}

class TaskDetails extends Component {
    constructor(props) {
      super(props);
      this.state = {
        classUpcoming : 'nav-link active',
        classNotScheduled : 'nav-link',
        classCompleted : 'nav-link',
        tabStaus: 1,
      }
    }
    componentDidMount(){
        
    }
  

render() {
    var taskDetailsData=[];
    const taskDetailsData1 = this.props.taskDetailsData ? this.props.taskDetailsData : '';
    taskDetailsData.push(taskDetailsData1);
    return(
      <div class="dash-content flex-grow-1 mytaskspge">
      <section class="sliders mb-sm-4">
         <div class="row">
            <div class="col-lg-12">
               <div class="card card-theme mb-4 nmecard">
                        <div class="card-body">
                           <div class="cuslessons">
                              <div class="row">
                                 <div class="col-md-12  d-lg-block col-lg-12 col-xl-12">
                                    <div class="card-body p-1 tblbdycrd">
                                         <div class="cus-table table-responsive">
                                            <table class="table">
                                               <thead>
                                                  <tr>
                                                     <th>Task</th>
                                                     <th>Student Name</th>
                                                     <th>Submitted Date</th>
                                                     <th>Comments</th>
                                                     <th width="150px">Actions</th>
                                                  </tr>
                                               </thead>
                                               <tbody>
                                                {
                                                  taskDetailsData.length > 0 ?
                                                    (taskDetailsData.map((tasksData, index) => (
                                                      <TaskDetailsItems tasks={tasksData} tasksDataUpload={this.props.tasksDataUpload}/>     
                                                    )))
                                                    :
                                                      <NoData apiCalled={this.props.tasksStatusData}/>
                                                }
                                               </tbody>
                                            </table>
                                         </div>
                                      </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
            </div>
         </div>
      </section>
   </div>
);
}
}
export default TaskDetails;