import React, { Component } from 'react';
import { Link } from "react-router-dom";
import photoshop from '../../assets/images/photoshop.png';
import python from '../../assets/images/python.png';
import NoData from '../NoData';

const details = (selectedtaskId,tasksDetails) => {
    const payload={
        taskId: 114,
    };
    return tasksDetails(payload);
}


const TaskItems=(props) => {
    const curriculumTitle = props.tasks ? props.tasks.curriculumTitle : '';
    const classId = props.tasks ? props.tasks.classId : '';
    const taskDesc = props.tasks ? props.tasks.taskDesc : '';
    const taskTitle = props.tasks ? props.tasks.taskTitle : '';
    const classIcon = props.tasks ? props.tasks.classIcon : '';
    const submissionDueDate = props.tasks ? props.tasks.submissionDueDate : '';
    const submissionStatus = props.tasks ? props.tasks.submissionStatus : '';
    const submittedDate = props.tasks ? props.tasks.submittedDate : '';
    const submitDate = (submissionStatus==='Submitted') ? submittedDate : submissionDueDate;
    const timeFormat = new Date(submitDate);
    return(
        <tr>
            <td>
            <div class="course d-flex align-items-center">
                    <img src={python} alt="python" class="img-fluid mr-2 rounded-circle"/>
                    <Link to="/TaskDetails" onClick={()=>details(classId,props.tasksDetails)} style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><p class="mb-0">{taskTitle}</p></Link>
                </div>
            </td>
            <td>
                {taskDesc.substr(0,100)}...
            </td>
            <td>
                <p class="mb-0">{timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'})}</p>
            </td>
        </tr>
    );
}


class Tasks extends Component {
    constructor(props) {
      super(props);
      this.setActiveTabCompleted = this.setActiveTabCompleted.bind(this);
      this.setActiveTabInProgress = this.setActiveTabInProgress.bind(this);
      this.state = {
        classUpcoming : 'nav-link active',
        classCompleted : 'nav-link',
        tabStaus: 1,
      }
    }
    componentDidMount() {
        this.props.tasksFetchData();
    }
    setActiveTabCompleted(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link',
            classCompleted: 'nav-link active',
            tabStaus: 2,
        }   
        );
      }
    setActiveTabInProgress(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link active',
            classCompleted: 'nav-link',
            tabStaus: 1
        });   
      }
    render() {
        var tasksArray = [];
        var progressCount = 0;
        const classStatus = this.state.tabStaus;
        if( classStatus===1){
            tasksArray = this.props.tasksStatusData ? this.props.tasksStatusData.progress : [];
            progressCount = tasksArray.length;
        } else {
            tasksArray = this.props.tasksStatusData ? this.props.tasksStatusData.completed : [];
        }
        
    return(
        <div className="dash-content flex-grow-1 mytaskspge">
           <div class="row">
                       <div class="col-md-8 col-7">
                           <h2 class="cus-heading mb-3">Review Tasks</h2>
                       </div>
                       
                   </div>
            <section className="sliders mb-sm-4">
                <div className="row">
                    <div className="col-lg-12">
                           <div className="tab-content">
                                <div className="tab-pane  active mytasks" id="upcoming">
                                    <div className="card card-theme mb-4 nmecard">
                                        <div className="card-body">
                                            <div className="cuslessons">
                                                <div className="row">
                                                    <div class="col-md-12  d-lg-block col-lg-12 col-xl-12">
                                                        <div class="card-body p-1 tblbdycrd">
                                                            <div class="cus-table table-responsive">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Course</th>
                                                                            <th>Task</th>
                                                                            <th>Date</th>
                                                                 
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            tasksArray.length > 0 ?
                                                                            (tasksArray.map((tasksData, index) => (
                                                                               <TaskItems tasks={tasksData} tasksDetails={this.props.tasksDetails}/>
                                                                                    
                                                                                
                                                                            )))
                                                                            :
                                                                            <NoData apiCalled={this.props.tasksStatusData}/>
                                                                            
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </div>
);
}
}
export default Tasks;