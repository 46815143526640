import { 
  ORDERS_FETCH_DATA,
  ORDERS_FETCH_DATA_SUCCESS,
  ORDERS_FETCH_DATA_ERROR,
} from './constants';

export function ordersFetchData(data) {
  return {
    type:  ORDERS_FETCH_DATA,
    data,
  };
}
export function ordersFetchDataSuccess(data) {
  return {
    type:  ORDERS_FETCH_DATA_SUCCESS,
	data,
  };
}
export function ordersFetchDataError(error) {
  return {
    type:  ORDERS_FETCH_DATA_ERROR,
	error,
  };
}
