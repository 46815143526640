import { 
  TEACHER_FETCH_DATA,
  TEACHER_FETCH_DATA_SUCCESS,
  TEACHER_FETCH_DATA_ERROR,
  TEACHER_SAVE_DATA,
  TEACHER_SAVE_DATA_SUCCESS,
  TEACHER_SAVE_DATA_ERROR,
  STUDENT_ATTENDANCE_DATA,
  STUDENT_ATTENDANCE_DATA_SUCCESS,
  STUDENT_ATTENDANCE_DATA_ERROR,
  LOG_OUT,
  ACCESS_TOKEN,
} from './constants';
import { sessionReducer }  from '../../SessionContext';

const initialState = {
  teacherSearchDataError: '',
  teacherSearchData: '',
  teacherSaveDataError: '',
  teacherSaveData: '',
  teacherId: '',
  studentAttendanceData: '',
  studentAttendanceDataErroer: '',
  accessToken: ''
};

const stateOb = sessionReducer ? JSON.parse(sessionReducer) : '';
export function teacherReducer(state = stateOb.teacherReducer || initialState, action) {
  switch (action.type) {
	case  TEACHER_FETCH_DATA:
      return {
        ...state,
        teacherId: action.data.studentId
     };
	case  TEACHER_FETCH_DATA_SUCCESS:
      return {
        ...state,
        teacherSearchData: action.data
     };
	case  TEACHER_FETCH_DATA_ERROR:
      return {
        ...state,
        teacherSearchDataError: 'Some error occured, please contact our customer spport'
     };
  case  TEACHER_SAVE_DATA:
      return {
        ...state,
     };
	case  TEACHER_SAVE_DATA_SUCCESS:
      return {
        ...state,
        teacherSearchData: action.data
     };
	case  TEACHER_SAVE_DATA_ERROR:
      return {
        ...state,
        teacherSearchDataError: 'Some error occured, please contact our customer spport'
     };
     case  STUDENT_ATTENDANCE_DATA:
      return {
        ...state,
     };
	  case  STUDENT_ATTENDANCE_DATA_SUCCESS:
      return {
        ...state,
        teacherAttendanceData: action.data
     };
    case  STUDENT_ATTENDANCE_DATA_ERROR:
      return {
        ...state,
        teacherAttendanceDataError: 'Some error occured, please contact our customer spport'
     };
    case LOG_OUT:
      return initialState;
    case ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.data
     };
    default:
      return state;
  }
}
