import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import * as serviceWorker from './serviceWorker';
import Layout from './containers/Layout';

import createRootReducer from './reducers/index'
import rootSaga from './sagas/index';
import { saveState } from './PresistedStore';

const sagaMiddleware = createSagaMiddleware();

// Need to be modified in production -  VERY IMPORTANT
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();


const configureStore=(preloadedState)=>{
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware
        // ... other middlewares ...
      ),
    ),
  )

  return store
}
//const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
const store = configureStore();
sagaMiddleware.run(rootSaga);

store.subscribe(()=>{
  saveState(store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <Layout history={history}/>
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
