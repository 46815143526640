import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Messages from '../../components/Messages';
import { messagesFetchData,messagesTeacher,messagesSend } from './actions';
import { makeSelectMessagesData,makeSelectMessagesTeacher } from './selectors'

const mapStateToProps = createStructuredSelector({
  messageData: makeSelectMessagesData(),
  messagesTeacherData: makeSelectMessagesTeacher(),
 });

export function mapDispatchToProps(dispatch) {
  return {
    messagesFetchData: (data) => dispatch(messagesFetchData(data)),
    messagesTeacher: (data) => dispatch(messagesTeacher(data)),
    sendMessages: (data) => dispatch(messagesSend(data)),
    dispatch,
  };
}

const MessagesContainer = connect(mapStateToProps,mapDispatchToProps)(Messages);

export default MessagesContainer;