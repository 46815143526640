import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Orders from '../../components/Orders';
import { ordersFetchData } from  './actions';
import { makeSelectOrdersData } from '../Orders/selectors';

const mapStateToProps = createStructuredSelector({
  ordersData: makeSelectOrdersData(),
 });

export function mapDispatchToProps(dispatch) {
  return {
    ordersFetchData: (studentData) => dispatch(ordersFetchData(studentData)),
    dispatch,
  };
}

const OrdersContainer = connect(mapStateToProps,mapDispatchToProps)(Orders);

export default OrdersContainer;