import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Courses from '../../components/Courses';
import { coursesFetchData,coursesDetails } from '../Courses/actions';
import { messagesSend } from '../Messages/actions';
import { makeSelectMessagesData } from '../Messages/selectors';
import {
  makeSelectStudentCoursesStatusData,
 } from '../Courses/selectors';


const mapStateToProps = createStructuredSelector({
  coursesStatus: makeSelectStudentCoursesStatusData(),
  messageData: makeSelectMessagesData(),
 });

export function mapDispatchToProps(dispatch) {
  return {
    coursesFetchData: (data) => dispatch(coursesFetchData(data)),
    courseDetails: (data) => dispatch(coursesDetails(data)),
    messagesSend: (data) => dispatch(messagesSend(data)),
    dispatch,
  };
}

const CourseContainer = connect(mapStateToProps,mapDispatchToProps)(Courses);

export default CourseContainer;