import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import EditProfile from '../../components/EditProfile';
import { teacherSaveData,uploadPhoto } from '../Teacher/actions';
import { makeSelectTeacherSaveData } from '../Teacher/selectors'

const mapStateToProps = createStructuredSelector({
  updatedData: makeSelectTeacherSaveData(),
});

export function mapDispatchToProps(dispatch) {
  return {
    updateStudent: (data) => dispatch(teacherSaveData(data)),
    uploadPhoto: (data) => dispatch(uploadPhoto(data)),
    dispatch,
  };
}

const EditProfileContainer = connect(mapStateToProps,mapDispatchToProps)(EditProfile);

export default EditProfileContainer;