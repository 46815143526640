import React, {useState} from 'react';
import kevin from '../../assets/images/noimage.png';
import Popup from '../Popup';

const ChatMessages = (props) =>{
   const message= props.messagesData ? props.messagesData.message : '';
   const startTime = props.messagesData ? props.messagesData.time : '';
   const senderId = props.messagesData ? props.messagesData.senderUserId : '';
   const timeFormat = new Date(startTime);
   const displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'})
   return(
         <div>
            {
               (senderId===1000)?
            (<div className="cus-message d-flex mb-2 usrmsg">
               <div className="message-detail">
                     <div className="message-head d-flex align-items-center">
                        <div className="time">{displayTime}</div>
                     </div>
                  <div className="message">
                     <p>{message}</p>
                  </div>
               </div>
            </div>)
            :
            (
               <div class="cus-message d-flex mb-2 replymsg">
                     <div class="message-detail">
                        <div class="message-head d-flex align-items-center">
                           <div class="time">9:22 PM</div>
                        </div>
                        <div class="message">
                           <p>{message}</p>
                        </div>
                     </div>
                  </div>
            )
            }
         </div>
   );
}

const MessageModal=(props) =>{
   const [modalIsOpen,setIsOpen] = useState(false);
   const [message,setMessage] = useState('');
   const teacherIcon = props.tasks ? props.tasks.classIcon : '';
   const selectedTeacherId = props.teacherId;
   const currentMessageArray = props.messagesData ? props.messagesData.filter((item)=>item.teacherId===selectedTeacherId):[];
   const openModal=()=>{
      setIsOpen(true);
    }
    const closeModal=()=>{
      setIsOpen(false);
    }
    const chatMessage = document.getElementById('chatMessage');
    const sMessage = props.messagesSendData;
    if(chatMessage){
       const message = document.getElementById('chatMessage').value;
       const payload={
          senderUserid: props.studentId,
          recipientUserid: props.teacherId,
          teacherId: props.teacherId,
          studentId: props.studentId,
          message: message,
       };
       const clsButton = document.getElementById("clsButton");
       
       /*chatMessage.addEventListener("keyup", function(event) {
          if (event.keyCode === 13) {
             event.preventDefault();
             sMessage(payload);
             clsButton.click();
          }
          });*/
    }
    const enterPressed=(event) =>{
      const clsButton = document.getElementById("clsButton");
      const payload={
         senderUserid: props.studentId,
         recipientUserid: props.teacherId,
         teacherId: props.teacherId,
         studentId: props.studentId,
         message: event.target.value,
      };
      var code = event.keyCode || event.which;
      if(code === 13) { //13 is the enter keycode
          //Do stuff in here
          sMessage(payload);
         clsButton.click();
   }
  }
   return(
      <span>
      <button onClick={openModal} className="btn btn-theme-light btn-rounded" data-toggle="modal" data-target="#messagepopup"><i className="fa fa-comment-alt"></i> Message</button>
      { modalIsOpen ?
     (<Popup>
      <div className="modal fade bgmdl" id="messagepopup" tabindex="-1" role="dialog" aria-labelledby="messagepopup" aria-hidden="true">
      <div className="modal-dialog" role="document">
         <div className="modal-content">
            <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">Message Teacher</h5>
               <button type="button" id="clsButton" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>closeModal(false)}>
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div className="modal-body">
               <div className="course d-flex align-items-center">
                  <img src={kevin} alt="userimage" className="img-fluid mr-2 rounded-circle"/>
                  <p className="mb-0">{props.courseTeacher}</p>
               </div>
               <hr/>
               {
                  currentMessageArray.length > 0 ?
                     (currentMessageArray.map((messagesData, index) => (
                        <ChatMessages index={index} key={index} messagesData={messagesData} />
                     )))
                  :
                  ''
               }
               
               <div className="form-group">
                  <textarea className="form-control" id="chatMessage" rows="3" onChange={e => setMessage(e.target.value)} onKeyPress={enterPressed.bind(this)}></textarea>
               </div>
            </div>
         </div>
      </div>
   </div>
   </Popup>)
      :
      ''
     }
      </span>
    );
}
export default MessageModal;