import React, { Component } from 'react';
import photoshop from '../../assets/images/noimage.png';
import { Link } from "react-router-dom";
import NoData from '../NoData';

export class TaskItems extends Component{
    constructor(props) {
        super(props);
        this.state ={
            file:null
          };
    }
    details(selectedTaskId,tasksDetails){
        const payload={
            taskId: selectedTaskId,
        };
        return tasksDetails(payload);
    }
    render() {
        const taskTitle = this.props.tasks ? this.props.tasks.courseTitle : '';
        var taskDescr = this.props.tasks ? this.props.tasks.taskDesc : '';
        var taskId = this.props.tasks ? this.props.tasks.taskId : '';
        if(taskDescr.length > 30) {
            taskDescr = `${taskDescr.substr(0,30)}...`;
        }
        const submissionDueDate = this.props.tasks ? this.props.tasks.submissionDueDate : '';
        const timeFormat = new Date(submissionDueDate);
        return(
            <tr>
                <td>
                    <div className="course d-flex align-items-center">
                        <img src={photoshop} alt="photoshop" className="img-fluid mr-2 rounded-circle" />
                        <p className="mb-0">{taskTitle}</p>
                    </div>
                </td>
                <td>
                <Link to='/TaskDetails' onClick={()=>this.details(taskId,this.props.tasksDetails)} >{taskDescr}</Link>
                </td>
                <td>
                    <p className="mb-0">{timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'})}</p>
                </td>
                <td>
                    <Link to='/TaskDetails' onClick={()=>this.details(taskId,this.props.tasksDetails)}style={{ cursor: 'default', color: 'inherit', textDecoration: 'inherit'}}><div className="btn btn-theme-light btn-rounded"><i className="fa fa-eye"></i></div></Link>
                </td>
                
            </tr>
        );
    }
}

const HomeTasks=(props) =>{
    var tasksArray = props.tasksData ? props.tasksData : [];
    var tasksCount = 0;
    if(Array.isArray(tasksArray)) {
        tasksCount = tasksArray.length;
        const sliceLength = (tasksArray.length > 3) ? 4 : tasksCount;
        tasksArray = tasksArray.slice(0, sliceLength);
    }
    return(
        <div className="card card-theme mb-4">
            <div className="card-header">
                <h4>Review Task</h4>
                    <Link to="/Tasks" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="viewall">View All <span className="badge badge-theme">{tasksCount}</span></div></Link>
            </div>
            <div className="card-body p-1 tblbdycrd">
                <div className="cus-table table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Course</th>
                                <th>Task</th>
                                <th>Deadline</th>
                                <th width="150px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasksArray.length > 0 ?
                            (tasksArray.map((tasksData, index) => (
                                <TaskItems tasks={tasksData} tasksFileUpload={props.tasksFileUpload} key={index} tasksDetails={props.tasksDetails} tasksDataUpload={props.tasksDataUpload}/>
                            )))
                            :
                            <NoData apiCalled={props.tasksData} />
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
);
}
export default HomeTasks;