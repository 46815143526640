import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import {
  API_END_POINT,
  API_TOKEN_END_POINT,
  GET_TOKEN
 } from './constants';
import { makeSelectAccesTokenData } from './selectors';
import {
  getTokenSuccess,
  getTokenError
 } from './actions';
import request, { headers, requestToken } from '../../utils/request';


export function* fetchTokenData(payload) {
  try{
    const requestURL = API_TOKEN_END_POINT;
    const response = yield call(requestToken, requestURL)
    yield put(getTokenSuccess(response));
  }catch (error) {
    yield put(getTokenError(error));
  }

}


export function* fetchTokenDataDaemon() {
  yield takeLatest(GET_TOKEN, fetchTokenData);
}

export default function* tokenSaga() {
  yield all([fetchTokenDataDaemon()]);
}