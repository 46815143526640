import React, { useState } from 'react';
import { Link } from "react-router-dom";
import student from '../../assets/images/noimage.png';
import logo from '../../assets/images/logo.png';
import calender from '../../assets/images/calander.png';
import mytask from '../../assets/images/my-task.png';
import message from '../../assets/images/message.png';
import courses from '../../assets/images/courses.png';
import cart from '../../assets/images/cart.png';
import Amplify, { Auth } from 'aws-amplify';
import jwt from 'jsonwebtoken';
import {getHeaders } from '../../containers/Teacher/tokenSaga';
import { setToken, headers } from '../../utils/request';
import './styles.css';

const Login=(props)=>{
    const tokenData = props.tokenData ? props.tokenData : [];
    const [cognitoError, setCognitoError ] = useState('');
    var poolId,clentId = '';
    if(tokenData.length > 0){
        poolId = tokenData[2] ? tokenData[2].value : '';
        clentId = tokenData[0] ? tokenData[0].value : '';
    }
      const modalId = document.getElementById('userLoginModal');
      if(!props.studentId){
        if(modalId){ modalId.style.display = 'block'; }
        }else{
        if(modalId){ modalId.style.display = 'none'; }
      }
    //const userPool = new CognitoUserPool(poolData);
    const clearData=()=>{
        document.getElementById('email').value="";
        document.getElementById('password').value="";
    }
    const closeMod=()=>{
        props.setShow(false);
        modalId.style.display = 'none';
        clearData();
    }
    const handleLogin = async (event) => {
        //event.preventDefault();
        Amplify.configure({
            Auth: {
                userPoolId: (tokenData[1]) ? tokenData[1].value : '', // Your user pool id here
                userPoolWebClientId: (tokenData[2]) ? tokenData[2].value : '', // Your client id here
                authenticationFlowType: 'USER_PASSWORD_AUTH',
            }
        });
        const userEmail = document.getElementById('email').value;
        const userPswd = document.getElementById('password').value;
        var signedIn = '';
        try{
            signedIn = await Auth.signIn(userEmail, userPswd);
            const userSession = await Auth.currentSession();
            const { jwtToken } = userSession.idToken;
            let decodedToken = jwt.decode(jwtToken, { complete: true });
            const userId = decodedToken.payload['custom:UserId'];
            const payload={
                studentId: userId || '',
                accessToken: jwtToken || ''
            };
            if(userId){
                headers['Authorization'] = jwtToken;
                setToken(payload)
                props.checkLogin(true);
                props.studentFetchData(payload);
            }
            modalId.style.display = 'none';
            closeMod();
        }catch(error){
            clearData();
            setCognitoError('Authentication Error.');
        }
       
      };
    return(
        <div className="userLoginP" id="userLoginModal" name="userLoginModalb" tabindex="-1" role="dialog" aria-labelledby="userLoginModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
           <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                    Login
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>closeMod()} >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div>
                        <h5 className="modal-title" id="exampleModalLabel">{cognitoError}</h5>
                    </div>
                    <h3>Login to Continue</h3>
                    <div className="txtlabel">Your Email</div>
                <div>
                  <input type="email"
                  id="email"
                  className="txtinput"
                  placeholder="Lorem Ipsum"
                  />
                </div>
                <br/>
                <div className="txtlabel">
                  Password
                </div>
                <div>
                <input
                  type="password"
                  id="password"
                  className="txtinput"
                  placeholder="********"
                />
                </div>
                <br/>
                <button id="loginBtn" className="btn btn-theme-light btn-rounded sbmitfoot" data-dismiss="modal" onClick={()=>handleLogin()}>
              Login
            </button>
        </div>
      </div>
      </div>
      </div>

    )
}

const SearchBar=(props) =>{
    var dashActive,lessonActive,taskActive,messageActive,courseAcive,ordersActive,profileActive,settingsActive,helpActive,logoutActive = 'menu-link';
    const [count, setCount] = useState(0);
    const [show, setShow] = useState(false);
    const [userLogged, checkLogin] = useState(false);
    const firstName = props.studentData ? props.studentData.firstName: '';
    const lastName = props.studentData ? props.studentData.lastName: '';
    const studentImage = props.studentData ? props.studentData.profileImage: '';
    const head =  getHeaders();
    if(props.studentId && !userLogged){
        checkLogin(!userLogged);
    }
    const clearSession=()=>{
        props.logout();
        signOut();
    }
    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    const addHideLeft=()=>{
        const leftdiv = document.getElementById("leftSBar");
        const bodydiv = document.getElementById("mainBody");
        leftdiv.classList.add("show-sidebar");
        bodydiv.classList.add("sidebar-body");
        
    }
    switch(count){
        case 1: {
            dashActive='menu-link';
            lessonActive='menu-link active';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 2: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link active';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 3: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link active';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 4: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link active';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 5: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link active';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        default:{
            dashActive='menu-link active';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
    }
    const dummyVoid = ()=>{
        return null;
    }

    // TODO student image need to be modified
    return(
        <>
            <header className="px-4 py-3 d-flex bg-white align-items-center dsktphead">
                        <div class="toggle-icon">
                            <a href="javascript:void(0);" class="btn btn-light togglebtn"><i class="fa fa-bars"></i></a>
                        </div>
                            <div className="d-flex align-items-center justify-content-end flex-grow-1 top-rightbar">
                                <div className="saerchbar px-4">
                                    <div className="dropdown mobile-search d-lg-none">
                                        <a href="#"><button className="btn btn-outline-secondary" type="button" id="search"><i className="fa fa-search"></i></button></a>
                                    </div>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search..." aria-label="Search" aria-describedby="search"></input>
                                        <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" id="search"><i className="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="notification pr-2">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bell"></i> <span className="badge badge-pill badge-warning"></span></button>
                                    </div>
                                </div>
                                {userLogged?
                                    <div className="userprofile pl-3">
                                    <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="d-flex">
                                        <div className="userimg">
                                            <img src={studentImage || student}  alt="user image" className="img-fluid" />
                                        </div>
                                        <div className="user-name">
                                            <h5 className="mb-0">{firstName}</h5>
                                            <p className="mb-0">{lastName}</p>
                                        </div>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <Link to="/Profile" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="dropdown-item">Profile</div></Link>
                                        <div className="dropdown-item" onClick={() => clearSession()}>Logout</div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <i className="fa fa-user" onClick={() => setShow(!show)}></i>
                                </div>
                                }
                            </div>
            </header>
            <div className="mobile-header">
                   <header className="px-4 py-3 d-flex bg-white align-items-center">
                      <div className="toggle-icon">
                         <a href="javascript:void(0);" className="btn btn-light togglebtn" onClick={()=>addHideLeft()} id="togBtn"><i className="fa fa-bars"></i></a>
                      </div>
                      <div className="d-flex align-items-center justify-content-end flex-grow-1 top-rightbar">
                            <a href="#" className="d-md-block"><img src={logo} alt="Jrcodebees" className="img-fluid lgimg" /></a>
                         <div className="notification pr-2">
                            <div className="btn-group">
                               <button type="button" className="btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-bell"></i> <span className="badge badge-pill badge-warning"></span></button>
                            </div>
                         </div>
                         {userLogged?
                         <div className="userprofile pl-3">
                            <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="d-flex">
                               <div className="userimg">
                                  <img src={student} alt="user image" className="img-fluid"/>
                               </div>
                               <div className="user-name">
                                  <h5 className="mb-0">{firstName}</h5>
                                  <p className="mb-0">{lastName}</p>
                               </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link to="/Profile" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="dropdown-item">Profile</div></Link>
                               <div className="dropdown-item" onClick={() => clearSession()}>Logout</div>
                            </div>
                         </div>
                         :
                            <div>
                                <i className="fa fa-user" onClick={() => setShow(!show)}></i>
                            </div>
                        }

                      </div>
                    </header>
                    <div className="input-group">
                       <input type="text" className="form-control" placeholder="Search..." aria-label="Search" aria-describedby="search"/>
                       <div className="input-group-append">
                          <button className="btn btn-outline-secondary" type="button" id="search"><i className="fa fa-search"></i></button>
                       </div>
                    </div>
                    <div className="moblst">
                        <ul>
                            <li className="menu-item" onClick={() => setCount(1)}>
                                <Link to="/Lessons" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={lessonActive}><img src={calender} alt="calander" className="img-fluid" /> <span>Schedule</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(2)}>
                               <Link to="/Tasks" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={taskActive}><img src={mytask} alt="my-task" className="img-fluid" /> <span>Tasks</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(3)}>
                                <Link to="/Messages" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={messageActive}><img src={message} alt="Messages" className="img-fluid" /> <span>Messages</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(4)}>
                                <Link to="/Courses" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={courseAcive}><img src={courses} alt="my-task" className="img-fluid" /> <span>Courses</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(5)}>
                                <Link to="/Orders" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={ordersActive}><img src={cart} alt="my-task" className="img-fluid" /> <span>Orders</span></div></Link>
                            </li>
                        </ul>
                    </div>
               </div>
               <Login studentId={props.studentId} setShow={setShow} show={show} studentFetchData={props.studentFetchData} checkLogin={checkLogin} tokenData={props.tokenData} getAccessToken={props.getAccessToken} userLogged={userLogged}/>
               </>
    );
}
export default SearchBar;