import React from 'react';

const NoData=(props)=>{
    if(props.apiCalled){
        return(
            <div>No data to display</div>
        );
    }
    return '';

}
export default NoData;