import { createSelector } from 'reselect';

const tasksStudentReducer = (state) => state.tasksStudentReducer;

export const makeSelectStudentTaskError = () => createSelector(
  tasksStudentReducer,
  (tasks) => {
	return tasks.tasksDataError;
  }
);
export const makeSelectStudentTaskData = () => createSelector(
  tasksStudentReducer,
  (tasks) => {
	return tasks.tasksData;
  }
);
export const makeSelectStudentTaskStatusError = () => createSelector(
  tasksStudentReducer,
  (tasks) => {
	return tasks.tasksDataStatusError;
  }
);
export const makeSelectStudentTaskStatusData = () => createSelector(
  tasksStudentReducer,
  (tasks) => {
	return tasks.tasksDataStatus;
  }
);
export const makeSelectTaskDetailsError = () => createSelector(
  tasksStudentReducer,
  (tasks) => {
	return tasks.tasksDetailsError;
  }
);
export const makeSelectTaskDetails = () => createSelector(
  tasksStudentReducer,
  (tasks) => {
	return tasks.tasksDetails;
  }
);