import { createSelector } from 'reselect';

const tokenReducer = (state) => state.tokenReducer;

export const makeSelectTokenData = () => createSelector(
  tokenReducer,
  (tokenstate) => {
	return tokenstate.tokenData;
  }
);
