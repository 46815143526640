import { createSelector } from 'reselect';

const messagesReducer = (state) => state.messagesReducer;

export const makeSelectMessagesError = () => createSelector(
  messagesReducer,
  (messages) => {
	return messages.messagesDataError;
  }
);
export const makeSelectMessagesData = () => createSelector(
  messagesReducer,
  (messages) => {
	return messages.messagesData;
  }
);
export const makeSelectMessagesTeacherError = () => createSelector(
  messagesReducer,
  (messages) => {
	return messages.messagesTeacherError;
  }
);
export const makeSelectMessagesTeacher = () => createSelector(
  messagesReducer,
  (messages) => {
	return messages.messagesTeacher;
  }
);
