import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CourseDetails from '../../components/CourseDetails';
import { lessonsCancel } from '../Lessons/actions';
import { makeSelectCourseDetails } from '../Courses/selectors'

const mapStateToProps = createStructuredSelector({
  courseDetails: makeSelectCourseDetails(),
});

export function mapDispatchToProps(dispatch) {
  return {
    lessonsCancel: (data) => dispatch(lessonsCancel(data)),
    dispatch,
  };
}

const CourseDetailsContainer = connect(mapStateToProps,mapDispatchToProps)(CourseDetails);

export default CourseDetailsContainer;