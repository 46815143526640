import { 
  TASKS_FETCH_DATA,
  TASKS_FETCH_DATA_SUCCESS,
  TASKS_FETCH_DATA_ERROR,
  TASKS_FETCH_DATA_STATUS_SUCCESS,
  TASKS_FETCH_DATA_STATUS_ERROR,
  TASKS_FILE_UPLOAD,
  TASKS_FILE_UPLOAD_SUCCESS,
  TASKS_FILE_UPLOAD_ERROR,
  TASKS_DATA_UPLOAD,
  TASKS_DATA_UPLOAD_SUCCESS,
  TASKS_DATA_UPLOAD_ERROR,
  TASKS_DETAILS,
  TASKS_DETAILS_SUCCESS,
  TASKS_DETAILS_ERROR,
} from './constants';

export function tasksFetchData(data) {
  return {
    type: TASKS_FETCH_DATA,
    data,
  };
}
export function tasksFetchDataSuccess(data) {
  return {
    type: TASKS_FETCH_DATA_SUCCESS,
	  data,
  };
}
export function tasksFetchDataError(error) {
  return {
    type: TASKS_FETCH_DATA_ERROR,
	  error,
  };
}
export function tasksFetchDataStatusSuccess(data) {
  return {
    type:  TASKS_FETCH_DATA_STATUS_SUCCESS,
	  data,
  };
}
export function tasksFetchDataStatusError(error) {
  return {
    type:  TASKS_FETCH_DATA_STATUS_ERROR,
	  error,
  };
}
export function tasksFileUpload(data) {
  return {
    type: TASKS_FILE_UPLOAD,
	  data,
  };
}
export function tasksFileUploadSuccess(data) {
  return {
    type:  TASKS_FILE_UPLOAD_SUCCESS,
	  data,
  };
}
export function tasksFileUploadError(error) {
  return {
    type:  TASKS_FILE_UPLOAD_ERROR,
	  error,
  };
}
export function tasksDataUpload(data) {
  return {
    type: TASKS_DATA_UPLOAD,
	  data,
  };
}
export function tasksDataUploadSuccess(data) {
  return {
    type: TASKS_DATA_UPLOAD_SUCCESS,
	  data,
  };
}
export function tasksDataUploadError(error) {
  return {
    type: TASKS_DATA_UPLOAD_ERROR,
	  error,
  };
}
//
export function tasksDetails(data) {
  return {
    type: TASKS_DETAILS,
	  data,
  };
}
export function tasksDetailsSuccess(data) {
  return {
    type: TASKS_DETAILS_SUCCESS,
	  data,
  };
}
export function tasksDetailsError(error) {
  return {
    type: TASKS_DETAILS_ERROR,
	  error,
  };
}