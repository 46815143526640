import { createSelector } from 'reselect';

const lessonsStudentReducer = (state) => state.lessonsStudentReducer;

export const makeSelectStudentLessonsError = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonsDataError;
  }
);
export const makeSelectStudentLessonsData = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonsSearchData;
  }
);
export const makeSelectStudentLessonsStatusError = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonsStatusError;
  }
);
export const makeSelectStudentLessonsStatus = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonsStatusData;
  }
);
export const makeSelectLessonDetailsFetch = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonDetailsFetch;
  }
);
export const makeSelectLessonDetails = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonDetails;
  }
);
export const makeSelectLessonDetailsError = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonDetailsError;
  }
);
export const makeSelectStudentLessonSchedule = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonScheduleData;
  }
);
export const makeSelectStudentLessonScheduleError = () => createSelector(
  lessonsStudentReducer,
  (lessonsData) => {
	return lessonsData.lessonScheduleError;
  }
);