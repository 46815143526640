import React from 'react';

const Footer=(props) =>{
    return(
        <footer>
            <div className="row">
                <div className="col-lg-12">
                    <p>© JuniorCodeBees 2020</p>
                </div>
            </div>
        </footer>
    );
}
export default Footer;