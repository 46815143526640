import { 
  ORDERS_FETCH_DATA,
  ORDERS_FETCH_DATA_SUCCESS,
  ORDERS_FETCH_DATA_ERROR,
} from './constants';

const initialState = {
  ordersDataError: '',
  ordersData: '',
};

export function ordersReducer(state = initialState, action) {
  switch (action.type) {
	case  ORDERS_FETCH_DATA:
      return {
        ...state,
     };
	case  ORDERS_FETCH_DATA_SUCCESS:
      return {
        ...state,
        ordersData: action.data
     };
	case  ORDERS_FETCH_DATA_ERROR:
      return {
        ...state,
        ordersDataError: 'Some error occured, please contact our customer spport'
     };
    default:
      return state;
  }
}
