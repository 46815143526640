import {
  TEACHER_FETCH_DATA,
  TEACHER_FETCH_DATA_SUCCESS,
  TEACHER_FETCH_DATA_ERROR,
  TEACHER_SAVE_DATA,
  TEACHER_SAVE_DATA_SUCCESS,
  TEACHER_SAVE_DATA_ERROR,
  UPLOAD_PROFILE_PHOTO,
  STUDENT_ATTENDANCE_DATA,
  STUDENT_ATTENDANCE_DATA_SUCCESS,
  STUDENT_ATTENDANCE_DATA_ERROR,
  STUDENT_ATTENDANCE_SAVE,
  STUDENT_ATTENDANCE_SAVE_SUCCESS,
  STUDENT_ATTENDANCE_SAVE_ERROR,
  LOG_OUT,
  ACCESS_TOKEN,
} from './constants';

export function teacherFetchData(data) {
  return {
    type:  TEACHER_FETCH_DATA,
    data,
  };
}
export function teacherFetchDataSuccess(data) {
  return {
    type:  TEACHER_FETCH_DATA_SUCCESS,
	data,
  };
}
export function teacherFetchDataError(error) {
  return {
    type:  TEACHER_FETCH_DATA_ERROR,
	  error,
  };
}
export function teacherSaveData(data) {
  return {
    type:  TEACHER_SAVE_DATA,
    data,
  };
}
export function teacherSaveDataSuccess(data) {
  return {
    type:  TEACHER_SAVE_DATA_SUCCESS,
	data,
  };
}
export function teacherSaveDataError(error) {
  return {
    type:  TEACHER_SAVE_DATA_ERROR,
	  error,
  };
}
export function uploadPhoto(data) {
  return {
    type: UPLOAD_PROFILE_PHOTO,
    data,
  };
}
export function studentAttendanceData(data) {
  return {
    type:  STUDENT_ATTENDANCE_DATA,
    data,
  };
}
export function studentAttendanceSuccess(data) {
  return {
    type:  STUDENT_ATTENDANCE_DATA_SUCCESS,
	data,
  };
}
export function studentAttendanceError(error) {
  return {
    type:  STUDENT_ATTENDANCE_DATA_ERROR,
	  error,
  };
}
export function studentAttendanceSave(data) {
  return {
    type:  STUDENT_ATTENDANCE_SAVE,
    data,
  };
}
export function studentAttendanceSaveSuccess(data) {
  return {
    type:  STUDENT_ATTENDANCE_SAVE_SUCCESS,
	data,
  };
}
export function studentAttendanceSaveError(error) {
  return {
    type:  STUDENT_ATTENDANCE_SAVE_ERROR,
	  error,
  };
}
export function logout() {
  return {
    type: LOG_OUT,
  };
}
export function getAccessToken(data) {
  return {
    type: ACCESS_TOKEN,
    data,
  };
}
