export const COURSES_FETCH_DATA = 'STUDENT_COURSES_STATUS';
export const COURSES_FETCH_DATA_SUCCESS = 'STUDENT_COURSES_DATA_SUCCESS';
export const COURSES_FETCH_DATA_ERROR = 'STUDENT_COURSES_DATA_ERROR';
export const ADV_FETCH_DATA = 'ADV_COURSES_DATA';
export const ADV_FETCH_DATA_SUCCESS = 'ADV_COURSES_DATA_SUCCESS';
export const ADV_FETCH_DATA_ERROR = 'ADV_COURSES_DATA_ERROR';
export const COURSES_FETCH_STATUS_SUCCESS = 'COURSES_FETCH_STATUS_SUCCESS';
export const COURSES_FETCH_STATUS_ERROR = 'COURSES_FETCH_STATUS_ERROR';
export const COURSES_DETAILS = 'COURSES_DETAILS';
export const COURSES_DETAILS_SUCCESS = 'COURSES_DETAILS_SUCCES';
export const COURSES_DETAILS_ERROR = 'COURSES_DETAILS_ERROR';
export const API_END_POINT = 'https://iae22t6gp5.execute-api.us-east-2.amazonaws.com';