import React from 'react';
import helpsupport from '../../assets/images/helpsupport.png';

const HelpSupport=(props) =>{
   return(
      <div class="helpsupport">
                    <div class="row">
                       <div class="col-md-12 col-12">
                           <h2 class="cus-heading mb-3">Help & Support</h2>
                       </div>
                    </div>
                    <div class="bimger">
                       <div class="container-fluid">
                          <div class="row">
                            <div class="col-md-4 col-lg-4 col-12 col-sm-4">
                                <img src={helpsupport} alt="Help" class="img-fluid"/>
                            </div>
                            <div class="col-md-4 col-lg-4 col-12 col-sm-4" style={{ 'padding-top': 'default'}}>
                                <p>Connect with dedicate experts </p>
                                <p>Get immidiate assistance </p>
                                <p>Raise all your queries </p>
                            </div>
                            <div class="col-md-4 col-lg-4 col-12 col-sm-4">
                                <div class="phonebox">
                                    <p><i class="fas fa-phone-volume"></i><span>Call us at</span><br/>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+1 123 456 789</p>
                                </div>
                                <div class="phonebox">
                                    <p><i class="fas fa-envelope-open-text"></i><span>Write to us</span><br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Response time less then 24 hours</p>
                                </div>
                            </div>
                           </div>
                        </div>
                    </div>
                    <div class="dash-content flex-grow-1 mytaskspge">
                   
                  </div>
               </div>
    );
}
export default HelpSupport;