import {
  GET_TOKEN,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_ERROR,
} from './constants';

export function getTokenData(data) {
  return {
    type: GET_TOKEN,
    data,
  };
}
export function getTokenSuccess(data) {
  return {
    type: GET_TOKEN_SUCCESS,
	data,
  };
}
export function getTokenError(error) {
  return {
    type: GET_TOKEN_ERROR,
	  error,
  };
}
