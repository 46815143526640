import React, { useState} from 'react';
import photoshop from '../../assets/images/noimage.png';
import crossicn from '../../assets/images/crossicn.png';
import Popup from '../Popup';
import moment from 'moment';
import './modalstyle.css';

const ScheduleDeleteModal=(props) =>{
   const [showModal, toggleModal] = useState(false);
   const origin = props.origin ? props.origin : 0;
   const scheduleId = props.scheduleId ? props.scheduleId : '';
   const teacherId = props.teacherId ? props.teacherId : '';
   const selectedDate = props.selectedDate ? props.selectedDate : '';
   const openModal=()=>{
      toggleModal(true);
    }
    function closeModal(boolSubmit){
      if(boolSubmit){
         const fromDate = moment(selectedDate).startOf('week').format();
         const toDate = moment(selectedDate).endOf('week').format();
         const getSchedulePayload = {
            teacherId: teacherId,
            startdate: fromDate,
            enddate: toDate
         }
         const deleteData ={
            scheduleId: scheduleId,
            getSchedulePayload: getSchedulePayload,
         }
         props.lessonsDelete(deleteData);
         
      }
      toggleModal(false);
    }

   return (
     <span>
        { (origin===1) ?
        <button class="btn btn-theme-light btn-rounded wticnmb" onClick={openModal} data-toggle="modal" data-target="#feedbackmod"><i class="fa fa-star"></i> Feedback</button>
        :
         <div class="clndrbx pinkbg">
				<a data-toggle="modal" data-target="#calendrpoup" onClick={openModal}>
               <div class="clndrdata">
                  <div class="climg">
                     <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                  </div>
                  <h3>Adobe Photo- shop: Level 1</h3>
                  <p>Adam Michels</p>
               </div>
				</a>
         </div>
        }
        
     { showModal ?
     (<Popup>
         <div class="modal fade bgmdl" id="calendrpoup" tabindex="-1" role="dialog" aria-labelledby="calendrpoup" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>closeModal(false)}>
                  <span aria-hidden="true"><img src={crossicn}/></span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="card card-theme mb-4">
                               <div class="card-header flex-column">
                                  <div class="course d-flex align-items-center">
                                     <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                                     <p class="mb-0">Adobe Photoshop : Level 1</p>
                                  </div>
                                  <h4>Lesson 2</h4>
                               </div>
                               <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-6">
                                               <p>Slot</p>
                                               <b>6 AM - 7 AM</b>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-6">
                                                <p>Status</p>
                                               <b>Scheduled</b>
                                            </div>
                                        </div>
                                        <div class="row"> 
                                         <div class="col-md-6 col-lg-6 col-6">
                                           <p>Booked By</p>
                                           <b>Adam Michels</b>
                                         </div>
                                        </div>
                                  
                               </div>
                               <div class="card-foot">
                                  
                                  <button class="btn btn-theme-light btn-rounded wticnmb" onClick={()=>closeModal(true)} data-dismiss="modal"><i class="fa fa-trash" aria-hidden="true"></i>Delete</button>
                               </div>
                            </div>
               </div>
            </div>
         </div>
      </div>
      </Popup>)
      :
      ''
}
      </span> 
    );
}
export default ScheduleDeleteModal;