import React, { Component } from 'react';
import music from '../../assets/images/music.png';
import { Auth } from 'aws-amplify';


class Settings extends Component {
    constructor(props) {
      super(props);
      this.state = {
        currentPassword: '',
        newPassword: '',
        cognitoMessage: ''
      }
    }
    setMessage(mesg){
        if(mesg==='SUCCESS'){
            this.setState({cognitoMessage:'Password Updated Successfully.'})
        }else{
            this.setState({cognitoMessage:'Please contact administrator to complete the process.'})
        }
        
    }
    
    handleSubmit(){
        const accessToken = this.props.studentData.studentId;
        const cPswd = document.getElementById('cPswd').value;
        const nPswd = document.getElementById('nPswd').value;
        Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, cPswd, nPswd);
        })
        .then(data => this.setMessage(data))
        .catch(err => this.setMessage(err));
        document.getElementById('cPswd').value="";
        document.getElementById('nPswd').value="";
    }
    
    cancelValues(e){
        this.props.history.push("/Profile");
      }
    render() {
        
        
    return(
        <div class="dash-content flex-grow-1">
            <div class="row">
                <div class="col-md-8 col-7">
                    <h2 class="cus-heading mb-3">Settings</h2>
                </div>
                <div class="col-md-8 col-7">
                    <h4>{this.state.cognitoMessage}</h4>
                </div>
            </div>
        <div class="dash-content flex-grow-1">
                    <section class="sliders mb-sm-4">
                       <form id="studentForm">
                       <div class="row">
                          <div class="col-lg-10 col-md-12">
                             <div class="card card-theme mb-4 prfle edit-profile">
                                <div class="card-header"> 
                                   <h4>Change Password</h4>
                                   <div class="side-buttons">
                                         <a href="#" class="save btn btn-theme btn-rounded" onClick={()=>this.handleSubmit()}>Save</a>
                                         <a href="#" class="cancel btn btn-theme-light btn-rounded" onClick={()=>this.cancelValues()}>Cancel</a>
                                   </div>
                                   
                                </div>
                                <div class="card-body p-1">
                                   <div class="cus-table table-responsive">
                                      <table class="table prfletable">
                                         <tbody>
                                            <tr class="spcng"></tr>
                                            <tr>
                                               <td>
                                                  <span><b>Current Password</b></span>
                                               </td>
                                               <td>
                                                    <div class="form-group">
                                                       <input type="password" class="form-control" id="cPswd" onChange={e => this.setState({ currentPassword: e.target.value })}/>
                                                   </div>
                                               </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                  <span><b>New Password</b></span>
                                               </td>
                                               <td>
                                                   <div class="form-group">
                                                       <input type="password" class="form-control" id="nPswd" onChange={e => this.setState({ newPassword: e.target.value })}/>
                                                   </div>
                                               </td>
                
                                            </tr>
                                            
                                           
                                         </tbody>
                                      </table>
                                   </div>
                                </div>
                             </div>
                             </div>
                             </div>
                             </form>
                             </section>
                             </div>
                             </div>
);
}
}
export default Settings;