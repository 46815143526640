import { 
  MESSAGES_FETCH_DATA,
  MESSAGES_FETCH_DATA_SUCCESS,
  MESSAGES_FETCH_DATA_ERROR,
  MESSAGES_TEACHER,
  MESSAGES_TEACHER_SUCCESS,
  MESSAGES_TEACHER_ERROR,
} from './constants';
import { LOG_OUT} from '../Teacher/constants';
import { sessionReducer }  from '../../SessionContext';

const initialState = {
  messagesDataError: '',
  messagesData: '',
  messagesTeacherError: '',
  messagesTeacher: '',
};

const stateOb = sessionReducer ? JSON.parse(sessionReducer) : '';
export function messagesReducer(state = stateOb.messagesReducer || initialState, action) {
  switch (action.type) {
	case  MESSAGES_FETCH_DATA:
      return {
        ...state,
     };
	case  MESSAGES_FETCH_DATA_SUCCESS:
      return {
        ...state,
        messagesData: action.data
     };
	case  MESSAGES_FETCH_DATA_ERROR:
      return {
        ...state,
        messagesDataError: 'Some error occured, please contact our customer spport'
     };
  case  MESSAGES_TEACHER:
      return {
        ...state,
     };
	case  MESSAGES_TEACHER_SUCCESS:
      return {
        ...state,
        messagesTeacher: action.data
     };
	case  MESSAGES_TEACHER_ERROR:
      return {
        ...state,
        messagesTeacherError: 'Some error occured, please contact our customer spport'
     };
  case LOG_OUT:
      return initialState;
  default:
      return state;
  }
}
