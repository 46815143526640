import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { TEACHER_FETCH_DATA, API_END_POINT } from '../Teacher/constants';
import { makeSelectTeacherId } from '../Teacher/selectors';
import { COURSES_FETCH_DATA, COURSES_DETAILS } from './constants';
import {
  coursesFetchDataSuccess,
  coursesFetchDataError,
  advFetchDataSuccess,
  advFetchDataError,
  coursesFetchStatusSuccess,
  coursesFetchStatusError,
  coursesDetailsSuccess,
  coursesDetailsError,
 } from './actions';
import request, { headers } from '../../utils/request';

export function* fetchCoursesData(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURLActive = `${API_END_POINT}/teacher/${studentId}/batch/active`;
  try{
    const responseActive = yield call(request, requestURLActive, { timeout: DEFAULT_TIMEOUT, headers});
    const response = { active: responseActive || [] };
	  yield put(coursesFetchDataSuccess(response));
  } catch (error) {
    yield put(coursesFetchDataError(error));
  }
  
}

export function* fetchAdvData(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${studentId}/advertisement`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers});
	  yield put(advFetchDataSuccess(response));
  } catch (error) {
    yield put(advFetchDataError(error));
  }
  
}

export function* fetchCoursesDataStatus(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURLActive = `${API_END_POINT}/teacher/${studentId}/batch/active`;
  const requestURLCompleted = `${API_END_POINT}/teacher/${studentId}/batch/completed`;
  try{
    const responseActive = yield call(request, requestURLActive, { timeout: DEFAULT_TIMEOUT, headers });
    const responseCompleted = yield call(request, requestURLCompleted, { timeout: DEFAULT_TIMEOUT, headers });
    const response = { active: responseActive, completed: responseCompleted };
	  yield put(coursesFetchStatusSuccess(response));
  } catch (error) {
    yield put(coursesFetchStatusError(error));
  }
}

export function* fetchCourseDetails(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const courseId = payload.data ? payload.data.courseId: '';
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${studentId}/batch/class/${courseId}`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(coursesDetailsSuccess(response));
  } catch (error) {
    yield put(coursesDetailsError(error));
  }
  
}



export function* fetchCoursesDataDaemon() {
  yield takeLatest(TEACHER_FETCH_DATA, fetchCoursesData);
}

export function* fetchAdvDataDaemon() {
  yield takeLatest(TEACHER_FETCH_DATA, fetchAdvData);
}
export function* fetchCoursesDataStatusDaemon() {
  yield takeLatest(COURSES_FETCH_DATA, fetchCoursesDataStatus);
}
export function* fetchCourseDetailsDaemon() {
  yield takeLatest(COURSES_DETAILS, fetchCourseDetails);
}


export default function* coursesSaga() {
  yield all([
    fetchCoursesDataDaemon(),
    fetchAdvDataDaemon(),
    fetchCoursesDataStatusDaemon(),
    fetchCourseDetailsDaemon()
  ]);
}