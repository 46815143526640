import React, { Component } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import photoshop from '../../assets/images/noimage.png';
import boy from '../../assets/images/noimage.png';
import userdesk from '../../assets/images/userdesk.png';
import CancelModal from '../CancelModal';
import NoData from '../NoData';
import { isArray } from 'lodash';

const details = (studentId,selectedlessonId,lessonDetails) => {
    const payload={
        studentId: studentId,
        lessonId: selectedlessonId,
    };
    return lessonDetails(payload);
}


const LessonItems=(props) => {
    var displayTime = '';
    var buttonDisabled = false;
    const lessonTeacher = '';
    const teacherId = props.teacherId ? props.teacherId : '';
    const lessonTitle = props.lessons ? props.lessons.lessonTitle : '';
    const lessonId = props.lessons ? props.lessons.classId : '';
    const lessonDescription = props.lessons ? props.lessons.lessonDescription : '';
    const lessonName = props.lessons ? props.lessons.lessonName : '';
    const classIcon = props.lessons ? props.lessons.lessonIcon : '';
    const startTime = props.lessons ? props.lessons.startTime : '';
    const zoomLink = props.lessons ? props.lessons.zoomlink : '';
    const classStatus = props.classStatus ? props.classStatus : 0;
    var scheduleText = startTime ? 'Cancel' : 'Schedule';
    const timeFormat = new Date(startTime);
    const defaultTime = new Date(null);
    var buttonClass = 'btn btn-theme-light btn-rounded wticnmb';
    if(defaultTime.getTime()!==timeFormat.getTime()) {
        displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'})
    }
    var displayTimeDisable = timeFormat.getTime();
    var todaydate = new Date().getTime();
    var timeGap = (displayTimeDisable-todaydate)/(1000*3600);
    if(zoomLink && (timeGap <= 24) && (timeGap >= 0)){
        buttonDisabled = true;
        scheduleText = 'Go To Class';
        buttonClass = 'btn btn-theme-light btn-rounded actvbtn wticnmb';
    }
    return(
       <>
         <div class="row weekrow">
            <div class="col-md-1">
               <h2 class="cus-heading mb-3">Week 1</h2>
            </div>
            <div class="col-md-11">
               <hr/>
            </div>
         </div>
         <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12">
               <div class="card card-theme mb-4">
                  <div class="card-header flex-column">
                     <div class="course d-flex align-items-center">
                        <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                        <p class="mb-0">Adobe Photoshop : Level 1</p>
                     </div>
                     <h4>Lesson 2</h4>
                  </div>
                  <div class="card-body">
                     <div class="row icns clndricn">
                        <div class="col-md-12 col-lg-12 brdr col-12">
                           <p><img src={boy}/> &nbsp;&nbsp;&nbsp;Adam Joan Philip</p>
                        </div>
                        <div class="col-md-12 col-lg-12 col-12">
                           <p><i class="fa fa-calendar-alt"></i>&nbsp;&nbsp;&nbsp; <span class="activedate">03 Nov 2020 | 12:00 PM - 01:00 PM</span></p>
                        </div>
                     </div>
                  </div>
                  <div class="card-foot">
                     <button class="btn btn-theme-light btn-rounded actvbtn wticnmb"><img src={userdesk} alt="photoshop" class="img-fluid"/> Go to class</button>
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12">
               <div class="card card-theme mb-4">
                  <div class="card-header flex-column">
                     <div class="course d-flex align-items-center">
                        <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                        <p class="mb-0">Adobe Photoshop : Level 1</p>
                     </div>
                     <h4>Lesson 2</h4>
                  </div>
                  <div class="card-body">
                     <div class="row icns clndricn">
                        <div class="col-md-12 col-lg-12 brdr col-12">
                           <p><img src={boy}/> &nbsp;&nbsp;&nbsp;Adam Joan Philip</p>
                        </div>
                        <div class="col-md-12 col-lg-12 col-12">
                           <p><i class="fa fa-calendar-alt"></i>&nbsp;&nbsp;&nbsp; <span>03 Nov 2020 | 12:00 PM - 01:00 PM</span></p>
                        </div>
                     </div>
                  </div>
                  <div class="card-foot">
                     <a href="calender.html"><button class="btn btn-theme-light btn-rounded wticnmb"><i class="fas fa-calendar-alt"></i> Calendar</button></a>
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12">
               <div class="card card-theme mb-4">
                  <div class="card-header flex-column">
                     <div class="course d-flex align-items-center">
                        <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                        <p class="mb-0">Adobe Photoshop : Level 1</p>
                     </div>
                     <h4>Lesson 2</h4>
                  </div>
                  <div class="card-body">
                     <div class="row icns clndricn">
                        <div class="col-md-12 col-lg-12 brdr col-12">
                           <p><img src={boy}/> &nbsp;&nbsp;&nbsp;Adam Joan Philip</p>
                        </div>
                        <div class="col-md-12 col-lg-12 col-12">
                           <p><i class="fa fa-calendar-alt"></i>&nbsp;&nbsp;&nbsp; <span>03 Nov 2020 | 12:00 PM - 01:00 PM</span></p>
                        </div>
                     </div>
                  </div>
                  <div class="card-foot">
                     <a href="calender.html"><button class="btn btn-theme-light btn-rounded wticnmb"><i class="fas fa-calendar-alt"></i> Calendar</button></a>
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3 col-sm-6 col-12">
               <div class="card card-theme mb-4">
                  <div class="card-header flex-column">
                     <div class="course d-flex align-items-center">
                        <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                        <p class="mb-0">Adobe Photoshop : Level 1</p>
                     </div>
                     <h4>Lesson 2</h4>
                  </div>
                  <div class="card-body">
                     <div class="row icns clndricn">
                        <div class="col-md-12 col-lg-12 brdr col-12">
                           <p><img src={boy}/> &nbsp;&nbsp;&nbsp;Adam Joan Philip</p>
                        </div>
                        <div class="col-md-12 col-lg-12 col-12">
                           <p><i class="fa fa-calendar-alt"></i>&nbsp;&nbsp;&nbsp; <span>03 Nov 2020 | 12:00 PM - 01:00 PM</span></p>
                        </div>
                     </div>
                  </div>
                  <div class="card-foot">
                     <a href="calender.html"><button class="btn btn-theme-light btn-rounded wticnmb"><i class="fas fa-calendar-alt"></i> Calendar</button></a>
                  </div>
               </div>
         </div>
      </div>
   </>
    );
}

class LessonsSchedule extends Component {
    constructor(props) {
      super(props);
      this.setActiveTabCompleted = this.setActiveTabCompleted.bind(this);
      this.setActiveTabUpcoming = this.setActiveTabUpcoming.bind(this);
      this.setActiveTabNotScheduled = this.setActiveTabNotScheduled.bind(this);
      this.state = {
        classUpcoming : 'nav-link active',
        classNotScheduled : 'nav-link',
        classCompleted : 'nav-link',
        tabStaus: 1,
        currentMonth: new Date(),
        currentWeeks: [],
      }
    }
    componentDidMount(){
         this.getScheduleDetails();
    }
    setActiveTabCompleted(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link',
            classNotScheduled : 'nav-link',
            classCompleted: 'nav-link active',
            tabStaus: 3,
        }   
        );
    }
    setActiveTabNotScheduled(e) {
        e.preventDefault();
        this.setState({
            classNotScheduled : 'nav-link active',
            classUpcoming: 'nav-link',
            classCompleted: 'nav-link',
            tabStaus: 2,
        }   
        );
    }
    setActiveTabUpcoming(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link active',
            classNotScheduled : 'nav-link',
            classCompleted: 'nav-link',
            tabStaus: 1
        });   
    }
   nextMonth = () => {
      this.setState({
         currentMonth:  moment(this.state.currentMonth).add(1,"month")
      });
   };
  
   prevMonth = () => {
      this.setState({
         currentMonth:  moment(this.state.currentMonth).subtract(1,"month")
      });
   };
   getWeeks=()=>{
      let firstDay = moment(this.state.currentMonth).startOf('month');
      let endDay = moment(this.state.currentMonth).endOf('month');

      let weeks = [];
      for (let i = firstDay.week(); i <= endDay.week(); i++){
         weeks.push(i)
      }
   }
   getScheduleDetails(){
      const teacherId = this.props.teacherId ? this.props.teacherId : '';
      var today = this.state.currentMonth;
      const fromDate = moment(today).startOf('month').format();
      const toDate = moment(today).endOf('month').format();
      const payload = {
         teacherId: teacherId,
         startdate: fromDate,
         enddate: toDate
      }
      this.props.lessonsSchedule(payload);
    }

render() {
    var lessonArray = [];
    const classStatus = this.state.tabStaus;
    const upcomingArray = this.props.lessonStatus ? this.props.lessonStatus.upcoming : [];
    const scheduleDetailsBooked = this.props.scheduleDetails ? this.props.scheduleDetails.filter((item)=>item.active==='Booked'):[];
    const teacherId = this.props.teacherId ? this.props.teacherId : '';
    const upcomingCount = isArray(upcomingArray) ? upcomingArray.length : 0;
    if( classStatus===1){
        lessonArray = this.props.lessonStatus ? this.props.lessonStatus.upcoming : [];
    } else if(classStatus===2){
        lessonArray = this.props.lessonStatus ? this.props.lessonStatus.notScheduled : [];
    }  else {
        lessonArray = this.props.lessonStatus ? this.props.lessonStatus.completed : [];
    }
    return(
        <div className="dash-content flex-grow-1">
           <div class="row">
            <div class="col-md-8 col-7">
                <h2 class="cus-heading mb-3">Lessons Schedule</h2>
            </div>
            {this.getWeeks()}
           </div>
            <section className="sliders mb-sm-4">
                <div className="row">
                    <div className="col-lg-12">
                        
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={this.state.classUpcoming} data-toggle="tab" href="#upcoming" onClick={this.setActiveTabUpcoming}>Upcoming &nbsp;&nbsp;<span className="badge badge-pill badge-warning">{upcomingCount}</span></a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.classNotScheduled} data-toggle="tab" href="#notscheduled" onClick={this.setActiveTabNotScheduled}>Not Scheduled</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.classCompleted} data-toggle="tab" href="#completed" onClick={this.setActiveTabCompleted}>Completed</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                              <div class="tab-pane  active" id="upcoming">
                                 <div class="card card-theme mb-4 nmecard clndrcard">
                                    <div class="card-body">
                                       <div class="cuslessons">
                                           <span class="btn-group weekbtn">
                                                <a class="btn" onClick={this.prevMonth}><i class="fas fa-angle-left"></i></a>
                                            	<a class="btn active">{ moment(this.state.currentMonth).format("MMMM yyyy")}</a>
                                            	<a class="btn" onClick={this.nextMonth}><i class="fas fa-angle-right"></i></a>
                                            </span>
                                           
                                    </div>
                                 </div>
                              </div>
                              </div>
                            
                              
                           </div>

                    </div>
                </div>
            </section>
        </div>
);
}
}
export default LessonsSchedule;