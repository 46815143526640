import React from 'react';
import photoshop from '../../assets/images/noimage.png';
import { Link } from "react-router-dom";
import messagepink from '../../assets/images/message-pink.png';
import Donut from '../DaughutGraph/Donut';
import NoData from '../NoData';


const CoursesItems=(index, courses)=>{
    const courseName = index.courses ? index.courses.curriculumname : '';
    const courseTeacher = index.courses ? index.courses.teachername : '';
    const curriculumIcon = index.courses ? index.courses.curriculumIcon : '';
    const coursePercentage = index.courses ? index.courses.percentageCompletion : 0;
    const curriculumDesc = index.courses ? index.courses.curriculumDesc : '';
    let activeItem = 'carousel-item';
    if(index.index===0){
        activeItem = 'carousel-item active';
    }
    // TODO photoshop from img needs to be removed
    return(
        <div className={activeItem}  key={index.index}>
            <div class="row align-items-center flex-column-reverse flex-sm-row">
                <div class="col-sm-8">
                    <div class="content-slider">
                        <div class="course-name d-flex align-items-center mb-2">
                            <img src={curriculumIcon || photoshop} alt="photoshop" class="img-fluid btn-rounded mr-3" />
                            <h4>{courseName}</h4>
                        </div>
                        <div class="course-des">
                            <p>{curriculumDesc.substr(0,142)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
//
const NewsItems=(index, advs)=>{
    let activeItem = 'carousel-item';
    if(index.index==0){
        activeItem = 'carousel-item active';
    }
    const promoTitle = index.advs ? index.advs.promoTitle : '';
    const advImage =index.advs ? index.advs.adImage : '';
    const promoText =index.advs ? index.advs.promoText : '';
    return(
        <div className={activeItem} key={index.index}>
        <div className="row align-items-center flex-column-reverse flex-sm-row">
            <div className="col-sm-7 order-1">
                <div className="content-slider">
                    <div className="course-name d-flex align-items-center mb-2">
                        <h4>{promoTitle.substr(0,35)}</h4>
                    </div>
                    <div className="course-des">
                        <p>{promoText.substr(0,142)}</p>
                    </div>
                </div>
            </div>
            <div className="col-sm-5 order-1 text-center text-sm-left">
                <img src={advImage} className="img-fluid mb-3 mb-sm-0" alt="graph" />
            </div>
            <div className="col-12 order-0 order-sm-1">
                <div className="text-center">
                    <button className="btn btn-theme btn-rounded">Invite Friend</button>
                </div>
            </div>
        </div>
    </div>
    );
}
const AdvItems = (props) =>{
    return(
        <div id="carouselExampleIndicators1" className="carousel slide px-5" data-ride="carousel">
            <div className="carousel-inner">
            {props.advArray.map((advData, index) => (
                <NewsItems index={index} advs={advData} key={advData.curriculumadId} />
                ))
            }
            </div>
            <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators1" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators1" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators1" data-slide-to="2"></li>
            </ol>
            <a className="carousel-control-prev" href="#carouselExampleIndicators1" role="button" data-slide="prev">
                <i className="fa fa-angle-left"></i>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators1" role="button" data-slide="next">
                <i className="fa fa-angle-right"></i>
                <span className="sr-only">Next</span>
            </a>
    </div>
    );
}
const Carousel=(props) =>{
    const activeCoursesArray = props.coursesData ? props.coursesData.active : [];
    const advArray = props.advData ? props.advData : [];
    const numOfCourses = activeCoursesArray.length || 0;
    return(
    <section className="sliders mb-sm-4">
        <div className="row">
            <div className="col-lg-6">
                <div className="card card-theme">
                    <div className="card-header">
                        <h4>Active Batch</h4>
                        <Link to="/Courses" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className="viewall">View All<span className="badge badge-theme">{numOfCourses}</span></div></Link>
                    </div>
                    <div className="card-body">
                        <div id="carouselExampleIndicators" className="carousel slide px-5" data-ride="carousel">
                            <div className="carousel-inner">
                                {activeCoursesArray.length > 0 ?
                                (activeCoursesArray.map((courseData, index) => (
				                  <CoursesItems index={index} courses={courseData} key={courseData.curriculumId} />
                                )))
                                :
                                <NoData apiCalled={props.coursesData} />
                                }
                                    
                            </div>
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            </ol>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <i className="fa fa-angle-left"></i>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <i className="fa fa-angle-right"></i>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                     </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="card card-theme h-100">
                    <div className="card-header">
                        <h4>News Board</h4>
                    </div>
                    <div className="card-body">
                        { advArray.length > 0 ? <AdvItems advArray={advArray}/> : <NoData apiCalled={props.advData}/> }
                    </div>
                </div>
            </div>
        </div>
    </section>
);
}
export default Carousel;