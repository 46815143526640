import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import { TEACHER_FETCH_DATA,
  API_END_POINT,
  TEACHER_SAVE_DATA,
  UPLOAD_PROFILE_PHOTO,
  TEACHER_SAVE_DATA_SUCCESS,
  STUDENT_ATTENDANCE_DATA,
  STUDENT_ATTENDANCE_SAVE
 } from './constants';
import { makeSelectTeacherId } from './selectors';
import {
  teacherFetchDataSuccess,
  teacherFetchDataError,
  teacherSaveDataSuccess,
  teacherSaveDataError,
  studentAttendanceSuccess,
  studentAttendanceError,
  studentAttendanceSaveSuccess,
  studentAttendanceSaveError
 } from './actions';
 import { makeSelectTokenData } from '../Tokens/selectors';
import request, { headers } from '../../utils/request';

export function* fetchTeacherData(payload) {
  console.log(' API_END_POINT',  API_END_POINT);
  const teacherId = yield select(makeSelectTeacherId());
  const tokenDt = yield select(makeSelectTokenData());
  headers['jbtenantid'] = tokenDt[0] ? tokenDt[0].tenantId : '';
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${teacherId}/profile`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers});
    var studentData = '';
    if(Array.isArray(response)) {
      studentData = response.filter((item)=> {return item.studentId === teacherId });
    }
	  yield put(teacherFetchDataSuccess(response[0]));
  } catch (error) {
    yield put(teacherFetchDataError(error));
  }
}

export function* saveTeacherData(payload) {
  const teacherId = yield select(makeSelectTeacherId());
  const data = payload.data || '';
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${teacherId}/saveprofile`;
  try{
    const response = yield call(request, requestURL, { method: 'PUT', data, timeout: DEFAULT_TIMEOUT, headers });
	  yield put(teacherSaveDataSuccess(response));
  } catch (error) {
    yield put(teacherSaveDataError(error));
  }
  
}

export function* photoUpload(payload) {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const teacherId = yield select(makeSelectTeacherId());
  const data = payload.data|| '';
  const requestURL = `${API_END_POINT}/teacher/${teacherId}/upload`;
  const DEFAULT_TIMEOUT = 20000;
  const res = {};
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
    yield put(teacherSaveDataSuccess(response));
  } catch (error) {
    yield put(teacherSaveDataError(error));
  }
}

export function* fetchStudentAttendanceData(payload) {
  const teacherId = yield select(makeSelectTeacherId());
  const batchId = 180;
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${teacherId}/batch/class/student/${batchId}`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers});
    var studentData = '';
    if(Array.isArray(response)) {
      studentData = response.filter((item)=> {return item.studentId === teacherId });
    }
	  yield put(studentAttendanceSuccess(response));
  } catch (error) {
    yield put(studentAttendanceError(error));
  }
}

export function* studentAttendanceSave(payload) {
  const teacherId = yield select(makeSelectTeacherId());
  const batchId = 180;
  const students = payload.data || '';
  const data = {students:students};
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${teacherId}/attendance`;
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
    yield put(studentAttendanceSaveSuccess(response));
  } catch (error) {
    yield put(studentAttendanceSaveError(error));
  }
  
}

export function* fetchStudentDataDaemon() {
  yield takeLatest([TEACHER_FETCH_DATA,TEACHER_SAVE_DATA_SUCCESS], fetchTeacherData);
}
export function* saveTeacherDataDaemon() {
  yield takeLatest(TEACHER_SAVE_DATA, saveTeacherData);
}
export function* photoUploadDaemon() {
  yield takeLatest(UPLOAD_PROFILE_PHOTO, photoUpload);
}
export function* fetchStudentAttendanceDataDaemon() {
  yield takeLatest(STUDENT_ATTENDANCE_DATA, fetchStudentAttendanceData);
}
export function* studentAttendanceSaveDaemon() {
  yield takeLatest(STUDENT_ATTENDANCE_SAVE, studentAttendanceSave);
}

export default function* teacherSaga() {
  yield all([
    fetchStudentDataDaemon(),
    saveTeacherDataDaemon(),
    photoUploadDaemon(),
    fetchStudentAttendanceDataDaemon(),
    studentAttendanceSaveDaemon()
    ]
    );
}