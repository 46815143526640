export const TASKS_FETCH_DATA_SUCCESS = 'TASKS_FETCH_DATA_SUCCESS';
export const TASKS_FETCH_DATA_ERROR = 'TASKS_FETCH_DATA_ERROR';
export const TASKS_FETCH_DATA = 'TASKS_FETCH_DATA';
export const TASKS_FETCH_DATA_STATUS_SUCCESS = 'TASKS_FETCH_DATA_STATUS_SUCCESS';
export const TASKS_FETCH_DATA_STATUS_ERROR = 'TASKS_FETCH_DATA_STATUS_ERROR';
export const TASKS_FILE_UPLOAD = 'TASK_FILE_UPLOAD';
export const TASKS_FILE_UPLOAD_SUCCESS = 'TASK_FILE_UPLOAD_SUCCESS';
export const TASKS_FILE_UPLOAD_ERROR = 'TASK_FILE_UPLOAD_ERROR';
export const TASKS_DATA_UPLOAD = 'TASK_DATA_UPLOAD';
export const TASKS_DATA_UPLOAD_SUCCESS = 'TASK_DATA_UPLOAD_SUCCESS';
export const TASKS_DATA_UPLOAD_ERROR = 'TASK_DATA_UPLOAD_ERROR';
export const TASKS_DETAILS = 'TASKS_DETAILS';
export const TASKS_DETAILS_SUCCESS = 'TASKS_DETAILS_SUCCESS';
export const TASKS_DETAILS_ERROR = 'TASKS_DETAILS_ERROR';
export const API_END_POINT = 'https://iae22t6gp5.execute-api.us-east-2.amazonaws.com';
