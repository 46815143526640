import { 
  TASKS_FETCH_DATA,
  TASKS_FETCH_DATA_SUCCESS,
  TASKS_FETCH_DATA_ERROR,
  TASKS_FETCH_DATA_STATUS_SUCCESS,
  TASKS_FETCH_DATA_STATUS_ERROR,
  TASKS_FILE_UPLOAD,
  TASKS_DETAILS,
  TASKS_DETAILS_SUCCESS,
  TASKS_DETAILS_ERROR,
} from './constants';
import { LOG_OUT} from '../Teacher/constants';
import { sessionReducer }  from '../../SessionContext';

const initialState = {
  tasksDataError: '',
  tasksData: '',
  tasksDataStatusError: '',
  tasksDataStatus: '',
  tasksDetailsError: '',
  tasksDetails: '',
};

const stateOb = sessionReducer ? JSON.parse(sessionReducer) : '';
export function tasksStudentReducer(state = stateOb.tasksStudentReducer || initialState, action) {
  switch (action.type) {
	case  TASKS_FETCH_DATA:
      return {
        ...state,
     };
	case  TASKS_FETCH_DATA_SUCCESS:
      return {
        ...state,
        tasksData: action.data
     };
	case  TASKS_FETCH_DATA_ERROR:
      return {
        ...state,
        tasksDataError: 'Some error occured, please contact our customer spport'
     };
  case  TASKS_FETCH_DATA_STATUS_SUCCESS:
      return {
        ...state,
        tasksDataStatus: action.data
     };
	case  TASKS_FETCH_DATA_STATUS_ERROR:
      return {
        ...state,
        tasksDataStatusError: 'Some error occured, please contact our customer spport'
     };
  case TASKS_FILE_UPLOAD:
    return {
      ...state,
    }
    case  TASKS_DETAILS:
      return {
        ...state,
     };
	case  TASKS_DETAILS_SUCCESS:
      return {
        ...state,
        tasksDetails: action.data
     };
	case  TASKS_DETAILS_ERROR:
      return {
        ...state,
        tasksDetailsError: 'Some error occured, please contact our customer spport'
     };
  case LOG_OUT:
    return initialState;
    default:
      return state;
  }
}
