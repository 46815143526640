import React, { Component } from 'react';
import { Link } from "react-router-dom";
import filters from '../../assets/images/filters.png';
import photoshop from '../../assets/images/noimage.png';
import books from '../../assets/images/books.png';
import clock from '../../assets/images/clock.png';
import ratings from '../../assets/images/ratings.png';
import MessageModal from '../MessageModal';
import NoData from '../NoData';

const details = (selectedCourseId,courseDetails) => {
    const payload={
        courseId: selectedCourseId,
    };
    return courseDetails(payload);
}

const CourseItems=(props) => {
    const curriculumId = props.courses ? props.courses.batchId : '';
    const teacherId = props.courses ? props.courses.teacherId : '';
    const teacherIcon = props.courses ? props.courses.teacherIcon : '';
    const studentId = props.courses ? props.courses.studentId : '';
    const courseName = props.courses ? props.courses.curriculumname : '';
    const numbertOfStudents = props.courses ? props.courses.numbertOfStudents : '';
    const curriculumIcon = props.courses ? props.courses.curriculumIcon : '';
    const numberOfLessons = props.courses ? props.courses.numberOfLessons : 0;
    const curriculumDesc = props.courses ? props.courses.curriculumDesc : '';
    const messagesData = props.messagesData || '';
    return(
        <div className="col-md-6 col-lg-6 col-xl-3 col-12">
            <div className="card card-theme mb-4">
                <div class="card-header flex-column ratingflex">
                    <div class="course d-flex align-items-center ratingtb">
                        <img src={curriculumIcon || photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                        <p class="mb-0">{courseName}</p>
                    </div>
                    <div class="course d-flex align-items-center">
                        <p class="ratings"><img src={ratings} alt="photoshop" class="img-fluid mr-2 rounded-circle"/> 3.0 (550)</p>
                    </div>
                </div>
            <div className="card-body">
                <div className="lesson-inner">
                    <div className="lesson-heading">{curriculumDesc.substr(0,140)}</div>
                </div>
                <div className="row icns">
                    <div className="col-md-4 col-lg-4 col-4 brdr">
                        <p><i className="fa fa-user-friends" aria-hidden="true"></i></p>
                        <p>{numbertOfStudents}</p>
                    </div>
                    <div className="col-md-4 col-lg-4 col-4 brdr">
                        <p><img src={books} alt="calander" className="img-fluid h30" /></p>
                        <p><b>{numberOfLessons}</b> Lessons</p>
                    </div>
                    <div className="col-md-4 col-lg-4 col-4">
                        <p><img src={clock} alt="calander" className="img-fluid h30" /></p>
                        <p><b> </b> </p>
                    </div>
                </div>
            </div>
            <div className="card-foot">
                <Link to="/CourseDetails" onClick={()=>details(curriculumId,props.courseDetails)} style={{ cursor: 'default', color: 'inherit', textDecoration: 'inherit'}}><button type="button" class="btn btn-theme-light btn-rounded" ><i class="fa fa-eye"></i> Details</button></Link>
                <MessageModal teacherId={teacherId} studentId={studentId} teacherIcon={teacherIcon} messagesData={messagesData} messagesSendData={props.messagesSend}/>
            </div>
            </div>
        </div>
    );
}


class Courses extends Component {
    constructor(props) {
      super(props);
      this.setActiveTabCompleted = this.setActiveTabCompleted.bind(this);
      this.setActiveTabInProgress = this.setActiveTabInProgress.bind(this);
      this.state = {
        classUpcoming : 'nav-link active',
        classCompleted : 'nav-link',
        tabStaus: 1,
      }
    }
    setActiveTabCompleted(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link',
            classCompleted: 'nav-link active',
            tabStaus: 2,
        }   
        );
      }
    setActiveTabInProgress(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link active',
            classCompleted: 'nav-link',
            tabStaus: 1
        });   
      }
    componentDidMount(){
        const payload={
            studentId:1000,
        };
        this.props.coursesFetchData(payload);
    }
    render() {
        var courseArray = [];
        const classStatus = this.state.tabStaus;
        if( classStatus===1){
            courseArray = this.props.coursesStatus ? this.props.coursesStatus.active : [];
        } else {
            courseArray = this.props.coursesStatus ? this.props.coursesStatus.completed : [];
        }
        const nMessages= Array.isArray(courseArray)? courseArray.length : 0;
    return(
        <div className="dash-content flex-grow-1 coursetxtpge">
             <div class="row">
                       <div class="col-md-8 col-7">
                           <h2 class="cus-heading mb-3">My Batches</h2>
                       </div>
                      
                   </div>
            <section className="sliders mb-sm-4">
                <div className="row">
                    <div className="col-lg-12">
                           <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a className={this.state.classUpcoming} data-toggle="tab" href="#upcoming" onClick={this.setActiveTabInProgress}>Active Courses &nbsp;&nbsp;<span className="badge badge-pill badge-warning">{nMessages}</span></a>
                              </li>
                              <li className="nav-item">
                                <a className={this.state.classCompleted} data-toggle="tab" href="#notscheduled" onClick={this.setActiveTabCompleted}>Completed</a>
                              </li>
                           </ul>
                           <div className="tab-content mycrses">
                              <div className="tab-pane  active" id="upcoming">
                                 <div className="card card-theme mb-4 nmecard">
                                        <div className="card-body">
                                            <div className="cuslessons">
                                                <div className="row">

                                                    {
                                                        courseArray.length > 0 ?
                                                        (courseArray.map((courseData, index) => (
                                                                <CourseItems index={index} courses={courseData} key={index} tasksFileUpload={this.props.tasksFileUpload} courseDetails={this.props.courseDetails} messagesData={this.props.messageData} messagesSend={this.props.messagesSend}/>
                                                            
                                                        )))
                                                        :
                                                        <NoData apiCalled={this.props.coursesStatus}/>
                                                        
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </div>
);
}
}
export default Courses;