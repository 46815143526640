import React, {useState} from 'react';
import photoshop from '../../assets/images/noimage.png';
import crossicn from '../../assets/images/crossicn.png';
import Popup from '../Popup';
import './modalstyles.css';


const cancelLesson = (cancelClassId,lessonsCancel,cancelMeetingId,studentId,refreshIndex) => {
   const comments = document.getElementById('commentsTextArea').value;
   const payload = {
      studentId: studentId,
      classId: cancelClassId,
      cancelComments: comments,
      meetingId: cancelMeetingId,
      refreshIndex: refreshIndex,
   }
   return lessonsCancel(payload); 
}

const CancelModal=(props) =>{
   const [modalIsOpen,setIsOpen] = useState(false);
   const [message,setMessage] = useState('');
   const lessonName = props.lessons ? props.lessons.lessonName : '';
   const title = props.lessons ? props.lessons.title : '';
   const lessonTitle = props.lessons ? props.lessons.lessonTitle : '';
   const classIcon = props.lessons ? props.lessons.classIcon : '';
   const startTime = props.lessons ? props.lessons.startTime : '';
   const timeFormat = new Date(startTime);
   const displayFormat=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
   const cancelClassId = props.lessons ? props.lessons.classId : '';
   const cancelMeetingId = props.lessons ? props.lessons.meetingId : '';
   const refreshIndex = props.refreshIndex ? props.refreshIndex : '';
  
   const openModal=()=>{
      setIsOpen(true);
    }
    const closeModal=()=>{
      setIsOpen(false);
    }
   return(
      <span>
      <button onClick={openModal} class='btn btn-theme-light btn-rounded wticnmb' data-toggle="modal" data-target="#reschdule"><i class="fas fa-calendar-alt"></i> Cancel</button>
      { modalIsOpen ?
     (<Popup>
        <div class="modal fade bgmdl" id="reschdule" tabindex="-1" role="dialog" aria-labelledby="reschdule" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Assignment Details</h5>
                  <button type="button" onClick={closeModal} class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><img src={crossicn}/></span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="course d-flex align-items-center">
                     <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                     <p class="mb-0">{lessonName}</p>
                  </div>
                  <p class="asnmnt">{title || lessonTitle}</p>
                  <p class="txtasn"> Scheduled Date &nbsp;&nbsp;&nbsp;<b>{displayFormat}</b></p>
               </div>
               <div class="modal-footer">
                  <div class="form-group">
                     <label for="exampleFormControlTextarea1">Request Reschedule</label>
                     <textarea class="form-control" id="commentsTextArea" rows="3" onChange={e => setMessage(e.target.value)}>Comments</textarea>
                  </div>
                  <button class="btn btn-theme-light btn-rounded sbmitfoot" data-dismiss="modal" onClick={() => cancelLesson(cancelClassId,props.lessonsCancel,cancelMeetingId,props.studentId,refreshIndex)}><i class="fa fa-arrow-up" aria-hidden="true"></i>Submit</button>
               </div>
            </div>
         </div>
         </div>
         </Popup>)
      :
      ''
     }
      </span>
    );
}
export default CancelModal;