import {
  MESSAGES_FETCH_DATA,
  MESSAGES_FETCH_DATA_SUCCESS,
  MESSAGES_FETCH_DATA_ERROR,
  MESSAGES_TEACHER,
  MESSAGES_TEACHER_SUCCESS,
  MESSAGES_TEACHER_ERROR,
  MESSAGES_SEND_DATA,
  MESSAGES_SEND_DATA_SUCCESS,
  MESSAGES_SEND_DATA_ERROR,
} from './constants';

export function messagesFetchData(data) {
  return {
    type:  MESSAGES_FETCH_DATA,
    data,
  };
}
export function messagesFetchDataSuccess(data) {
  return {
    type:  MESSAGES_FETCH_DATA_SUCCESS,
	data,
  };
}
export function messagesFetchDataError(error) {
  return {
    type:  MESSAGES_FETCH_DATA_ERROR,
	error,
  };
}
export function messagesTeacher(data) {
  return {
    type: MESSAGES_TEACHER,
    data,
  };
}
export function messagesTeacherSuccess(data) {
  return {
    type: MESSAGES_TEACHER_SUCCESS,
	data,
  };
}
export function messagesTeacherError(error) {
  return {
    type: MESSAGES_TEACHER_ERROR,
	error,
  };
}
export function messagesSend(data) {
  return {
    type: MESSAGES_SEND_DATA,
    data,
  };
}
export function messagesSendDataSuccess(data) {
  return {
    type: MESSAGES_SEND_DATA_SUCCESS,
	  data,
  };
}
export function messagesSendDataError(error) {
  return {
    type: MESSAGES_SEND_DATA_ERROR,
	error,
  };
}
