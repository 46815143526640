import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AttendanceModal from '../../components/AttendanceModal';
import { studentAttendanceData, studentAttendanceSave } from '../Teacher/actions'
import { makeSelectStudentAttendanceData } from '../Teacher/selectors'

const mapStateToProps = createStructuredSelector({
  studentAttendanceDetails: makeSelectStudentAttendanceData(),
});

export function mapDispatchToProps(dispatch) {
  return {
    studentAttendanceData: (data) => dispatch(studentAttendanceData(data)),
    studentAttendanceSave: (data) => dispatch(studentAttendanceSave(data)),
    dispatch,
  };
}

const AttendanceContainer = connect(mapStateToProps,mapDispatchToProps)(AttendanceModal);

export default AttendanceContainer;