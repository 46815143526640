export const MESSAGES_FETCH_DATA = 'MESSAGES_LESSONS_FETCH_DATA';
export const MESSAGES_FETCH_DATA_SUCCESS = 'MESSAGES_FETCH_DATA_SUCCESS';
export const MESSAGES_FETCH_DATA_ERROR = 'MESSAGES_FETCH_DATA_ERROR';
export const MESSAGES_TEACHER = 'MESSAGES_TEACHER';
export const MESSAGES_TEACHER_SUCCESS = 'MESSAGES_TEACHER_SUCCESS';
export const MESSAGES_TEACHER_ERROR = 'MESSAGES_TEACHER_ERROR';
export const MESSAGES_SEND_DATA = 'MESSAGES_SEND_DATA';
export const MESSAGES_SEND_DATA_SUCCESS = 'MESSAGES_SEND_DATA_SUCCESS';
export const MESSAGES_SEND_DATA_ERROR = 'MESSAGES_SEND_DATA_ERROR';
export const API_END_POINT = 'https://iae22t6gp5.execute-api.us-east-2.amazonaws.com';
