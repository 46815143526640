import { 
  COURSES_FETCH_DATA,
  COURSES_FETCH_DATA_SUCCESS,
  COURSES_FETCH_DATA_ERROR,
  COURSES_FETCH_STATUS_SUCCESS,
  COURSES_FETCH_STATUS_ERROR,
  ADV_FETCH_DATA_SUCCESS,
  ADV_FETCH_DATA_ERROR,
  COURSES_DETAILS,
  COURSES_DETAILS_SUCCESS,
  COURSES_DETAILS_ERROR,
} from './constants';

export function coursesFetchData(data) {
  return {
    type: COURSES_FETCH_DATA,
    data,
  };
}
export function coursesFetchDataSuccess(data) {
  return {
    type: COURSES_FETCH_DATA_SUCCESS,
	data,
  };
}
export function coursesFetchDataError(error) {
  return {
    type: COURSES_FETCH_DATA_ERROR,
	  error,
  };
}
export function advFetchDataSuccess(data) {
  return {
    type: ADV_FETCH_DATA_SUCCESS,
	  data,
  };
}
export function advFetchDataError(error) {
  return {
    type: ADV_FETCH_DATA_ERROR,
	  error,
  };
}
export function coursesFetchStatusSuccess(data) {
  return {
    type:  COURSES_FETCH_STATUS_SUCCESS,
	  data,
  };
}
export function coursesFetchStatusError(error) {
  return {
    type:  COURSES_FETCH_STATUS_ERROR,
	  error,
  };
}
export function coursesDetails(data) {
  return {
    type: COURSES_DETAILS,
    data,
  };
}
export function coursesDetailsSuccess(data) {
  return {
    type: COURSES_DETAILS_SUCCESS,
	data,
  };
}
export function coursesDetailsError(error) {
  return {
    type: COURSES_DETAILS_ERROR,
	  error,
  };
}