import React, { Component } from 'react';
import user from '../../assets/images/user.png';
import userGirl from '../../assets/images/user-girl.png';
import { isArray } from 'lodash';
import { sendMessages } from '../../containers/Messages/sagas';


const MesgItems=(props) => {
    const teacherName = props.messages ? props.messages.teacherName : '';
    const teacherId = props.messages ? props.messages.teacherId : '';
    const teacherIcon = props.messages ? props.messages.teacherIcon : '';
    const submissionDueDate = props.messages ? props.messages.submissionDueDate : '';
    const timeFormat = new Date(submissionDueDate);
    const selectTeacher=(teacherId)=>{
       props.setTeacherId(teacherId);
    }
    const mobShow=()=>{
      const ms = document.getElementById("msgpp");
      const bs = document.getElementById("hdm");
      if(window.screen.width < 1100){
         ms.style.display="block";
         bs.style.display="none";
      }
   }
    return(
      <a href="javascript:void(0);" class="mbmsgpp" onClick={()=>mobShow()}>
      <div class="cus-message d-flex mb-2" onClick={()=>selectTeacher(teacherId)}>
         <div class="userimg mr-2">
            <img src={teacherIcon || user} alt="user" class="img-fluid"/>
         </div>
         <div class="message-detail">
            <div class="message-head d-flex align-items-center">
               <h6 class="name mb-0">{teacherName}</h6>
            </div>
         </div>
      </div>
    </a>
    );
}
const ChatMessages=(props)=>{
   const message= props.messagesData ? props.messagesData.message : '';
   const startTime = props.messagesData ? props.messagesData.time : '';
   const senderId = props.messagesData ? props.messagesData.senderUserId : '';
   const teacherId = props.messagesData ? props.messagesData.teacherId : '';
   const timeFormat = new Date(startTime);
   const displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
   const alignClass = (senderId===teacherId)? 'cus-message d-flex mb-2 usrmsg' : ' cus-message d-flex mb-2 replymsg';
   return(
      <>
       {(senderId!==teacherId) ?
                                          (<div className="cus-message d-flex mb-2 usrmsg">
                                             <div class="userimg mr-2">
                                                <img src={user} alt="user" class="img-fluid"/>
                                             </div>
                                             <div class="message-detail">
                                                <div class="message-head d-flex align-items-center">
                                                   <div class="time">{displayTime}</div>
                                                </div>
                                                <div class="message">
                                                   <p>{message}</p>
                                                </div>
                                             </div>
                                          </div>
                                          )
                                          :
                                          (<div className="cus-message d-flex mb-2 replymsg">
                                             <div class="message-detail">
                                                <div class="message-head d-flex align-items-center">
                                                   <div class="time">{displayTime}</div>
                                                </div>
                                                <div class="message">
                                                   <p>{message}</p>
                                                </div>
                                             </div>
                                             <div class="userimg mr-2">
                                                <img src={userGirl} alt="user" class="img-fluid"/>
                                             </div>
                                          </div>)
                                          
         }
      </>
   );
}

class Messages extends Component {
    constructor(props) {
      super(props);
      this.setActiveTabCompleted = this.setActiveTabCompleted.bind(this);
      this.setActiveTabInProgress = this.setActiveTabInProgress.bind(this);
      this.setTeacherId = this.setTeacherId.bind(this);
      this.desShow = this.desShow.bind(this);
      this.state = {
        classUpcoming : 'nav-link active',
        classCompleted : 'nav-link',
        tabStaus: 1,
        selctedTeacherId: 0,
        msg: '',
      }
    }
    componentDidMount() {
        this.props.messagesTeacher();
    }
    setTeacherId(teacherId){
      this.setState({
         selctedTeacherId: teacherId
      });
    }
    setActiveTabCompleted(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link',
            classCompleted: 'nav-link active',
            tabStaus: 2,
        }   
        );
      }
    setActiveTabInProgress(e) {
        e.preventDefault();
        this.setState({
            classUpcoming: 'nav-link active',
            classCompleted: 'nav-link',
            tabStaus: 1
        });   
      }
  
   desShow(){
      const ms = document.getElementById("msgpp");
      const bs = document.getElementById("hdm");
      if(window.screen.width < 1100){
         ms.style.display="none";
         bs.style.display="block";
      }
   }
    render() {
        const classStatus = this.state.tabStaus;
        var disableChat = false;
        var selectedStudentId = 0;
        const messageData= this.props.messageData ? this.props.messageData : [];
        const studentData=this.props.studentData ? this.props.studentData : '';
        const nMessages= Array.isArray(messageData)? messageData.length : 0;
        var messagesTeachers = this.props.messagesTeacherData ? this.props.messagesTeacherData : [];
        if(this.state.selctedTeacherId===0){
         selectedStudentId = (isArray(messageData) && messageData.length) > 0 ? messageData[0].studentId : 0;
        }else{
         selectedStudentId = this.state.selctedTeacherId;
        }
        if(selectedStudentId===0){
         disableChat=true;
        }
        const currentMessageArrays = this.props.messageData ? this.props.messageData.filter((item)=>item.studentId===selectedStudentId):[];
        const messagesTeacher = messagesTeachers.slice(0,4);
        const currentMessageArray =currentMessageArrays.reverse();
         const sMessage = this.props.sendMessages;
         const send=(origin)=>{
            var chatBoxTxt="";
            if(origin==1){
               chatBoxTxt = document.getElementById("exampleFormControlTextarea1").value;
            }else if(origin==2){
               chatBoxTxt = document.getElementById("chatMessage").value;
            }
            const payload={
               senderUserid: this.props.teacherId,
               recipientUserid: selectedStudentId,
               teacherId: this.props.teacherId,
               studentId: selectedStudentId,
               message: chatBoxTxt,
            };
            sMessage(payload);
         }
                
    return(
        <div className="dash-content flex-grow-1 msgpagetxt">
            <div class="row">
                <div class="col-md-8 col-7">
                    <h2 class="cus-heading mb-3">Messages</h2>
                </div>
            </div>
            <section class="sliders mb-sm-4">
               <div class="row">
               <div class="col-lg-12">
                  <ul class="nav nav-tabs">
                     <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#recent">Recent Chats &nbsp;&nbsp;<span class="badge badge-pill badge-warning">{nMessages}</span></a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#archived">Archived</a>
                     </li>
                  </ul>
                  <div class="tab-content">
                     <div class="tab-pane  active" id="recent">
                        <div class="row">
                           <div class="msgppdiv" id="msgpp">
                                      <div class="cus-message d-flex mdcstmmsg">
                                          <a href="javascript:void(0);" class="hidedv" onClick={()=>this.desShow()}><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                                         <div class="userimg mr-2">
                                            <img src={user} alt="user" class="img-fluid"/>
                                         </div>
                                         <div class="message-detail">
                                            <div class="message-head d-flex align-items-center">
                                               <h6 class="name mb-0">{studentData.firstName}&nbsp;{studentData.lastName}</h6>
                                            </div>
                                            <div class="message">
                                               <p>{studentData.email}</p>
                                            </div>
                                         </div>
                                      </div>
                                       <div class="btm_message_text">
                                       {
                                          currentMessageArray.length > 0 ?
                                             (currentMessageArray.map((messagesData, index) => (
                                                <ChatMessages index={index} key={index} messagesData={messagesData}/>
                                             )))
                                             :
                                             ''
                                       }
                                          <div class="form-group">
                                             <textarea class="form-control" id="exampleFormControlTextarea1" rows="1"> Write Your Message</textarea>
                                          </div>
                                          <div style={{ margin :'8px'}}>
                                             <p>
                                             <button type="button" class="btn btn-theme-light btn-rounded" onClick={()=>send(1)}><i className="fa fa-comment-alt"></i> Send Message</button>
                                             </p>
                                          </div>
                                       </div>
                           </div>
                           <div class="col-lg-4 col-md-5 hdmbf" id="hdm">
                              <div class="card-massageinner">
                              {
                                          messagesTeacher.length > 0 ?
                                             (messagesTeacher.map((messagesData, index) => (
                                                <MesgItems index={index} messages={messagesData} key={index} setTeacherId={this.setTeacherId}/>
                                             )))
                                             :
                                             ''
                                       }
                              </div>
                           </div>
                           <div class="col-lg-8 col-md-7">
                                       <h2 class="cus-heading mb-3">{studentData.firstName}&nbsp;{studentData.lastName}</h2>
                                       <hr/>
                                       <div class="btm_message_text">
                                       {
                                          currentMessageArray.length > 0 ?
                                             (currentMessageArray.map((messagesData, index) => (
                                                <ChatMessages index={index} key={index} messagesData={messagesData}/>
                                             )))
                                             :
                                             ''
                                       }
                                          
                                          <div>
                                             <textarea class="form-control" id="chatMessage" rows="3" onChange={e => this.setState({ msg: e.target.value })} disabled={disableChat} ></textarea>
                                          </div>
                                          <div style={{ margin :'8px'}}>
                                             <p>
                                             <button type="button" class="btn btn-theme-light btn-rounded" onClick={()=>send(2)}><i className="fa fa-comment-alt"></i> Send Message</button>
                                             </p>
                                          </div>
                                       </div>
                                    </div>    
                        </div>
                     </div>
                  </div>
                </div>
                </div>
            </section>
        </div>
);
}
}
export default Messages;