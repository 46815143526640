import { createSelector } from 'reselect';

const ordersReducer = (state) => state.ordersReducer;

export const makeSelectOrdersError = () => createSelector(
  ordersReducer,
  (orders) => {
	return orders.ordersDataError;
  }
);
export const makeSelectOrdersData = () => createSelector(
  ordersReducer,
  (orders) => {
	return orders.ordersData;
  }
);
