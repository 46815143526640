
    // Loading persisted state from localStorage, no need to access
    // this method from the outside
    export const loadState=()=> {
      try {
        let serializedState = localStorage.getItem('stateTeacher');
  
        if (serializedState === null) {
          return undefined;
        }
  
        return JSON.parse(serializedState);
      } catch (err) {
        return undefined;
      }
    }
  
    // Saving persisted state to localStorage every time something
    // changes in the Redux Store (This happens because of the subscribe() 
    // in the initStore-method). No need to access this method from the outside
    export const saveState=(state)=>{
      try {
        let serializedState = JSON.stringify(state);
        localStorage.setItem('stateTeacher', serializedState);
      } catch (err) {}
    }
  