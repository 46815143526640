import React, { useState} from 'react';
import crossicn from '../../assets/images/crossicn.png';
import Popup from '../Popup';
import moment from 'moment';
import './modalstyle.css';



const ScheduleClassModal=(props) =>{
   const [scheduleDate, setScheduleDate] = useState('');
   const [scheduleTimeFrom, setScheduleTimeFrom] = useState('');
   const [scheduleTimeTo, setScheduleTimeTo] = useState('');
   const [errorMessage, seterrorMessage] = useState('');
   const [timezone, setTimezone] = useState('');
   const [validation, setValidation] = useState('');
   const [showModal, toggleModal] = useState(false);

   const className = props.rates ? props.rates.className : '';
   const teacherName = props.rates ? props.rates.teacherName : '';
   const teacherProfilephoto = props.rates ? props.rates.teacherProfilephoto : '';
   const origin = props.origin ? props.origin : 0;
   const studentScheduleId = props.rates ? props.rates.studentScheduleId : '';
   const getTimeZones=()=>{
      const zoneArray = moment.tz.names();
      var zoneNames = [];
      zoneArray.forEach((item)=>{
         zoneNames.push(
            <option value={item}>{item}</option>
            );
      }
   
      )
      return  zoneNames;
   }
   const getFromRToTime=()=>{
      const tArray = props.selectedSession ? props.selectedSession : '';
      var timeRange = [];
      tArray.forEach((item)=>{
         const tValue = item.t + ' ' + item.noon;
         timeRange.push(
            <option value={tValue}>{tValue}</option>
            );
      }
      )
      return  timeRange;
   }
   var toArray='';
   if(getFromRToTime().length > 0){
      toArray=getFromRToTime();
      toArray.splice(0,1)
   }
   const openModal=()=>{
      toggleModal(true);
   }
   const resetValues=()=>{
      document.getElementById('inputScheduleDate').value='';
      document.getElementById('inputTimeFrom').value='';
      document.getElementById('inputTimeTo').value='';
      document.getElementById('inputTimeZone').value='';
      setScheduleDate('');
   }
   function closeModal(boolSubmit){
      if(boolSubmit){
         const scheduleDt = document.getElementById('inputScheduleDate').value;
         const inputTimeFrom = document.getElementById('inputTimeFrom').value;
         const inputTimeTo = document.getElementById('inputTimeTo').value;
         //const tzone = document.getElementById('inputTimeZone').value;
         const tzone = moment.tz.guess();
         if(scheduleDate===''){
            return '';
         }
         var a = moment(scheduleDt);
         var b = moment();
         const dayDiff = a.diff(b,'days');
         if(dayDiff < 2){
            //seterrorMessage('Please select date with 2 days gap');
            //return '';
         }
         const ratingData ={
            teacherId: props.teacherId,
            selectedDate: scheduleDt,
            startTime: inputTimeFrom,
            endTime: inputTimeTo,
            clientLocalTimezone: tzone,
         }
         props.teacherScheduleUpdate(ratingData);
         props.selectDay(scheduleDt);
         resetValues();
      }
      toggleModal(false);
   }

   return (
     <span>
        { (origin===1) ?
        <button class="btn btn-theme-light btn-rounded wticnmb" onClick={openModal} data-toggle="modal" data-target="#feedbackmod"><i class="fa fa-star"></i> Feedback</button>
        :
        <button class="btn btn-theme-light btn-rounded filters" onClick={()=>openModal()} data-toggle="modal" data-target="#schedulepopup"><i class="fa fa-plus" aria-hidden="true"></i> Schedule Class</button>
        }
        
     { showModal ?
     (<Popup> 
       <div class="modal fade bgmdl" id="schedulepopup" tabindex="-1" role="dialog" aria-labelledby="schedulepopup" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel"><i class="fa fa-calendar-alt"></i>&nbsp;Schedule Class</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>closeModal(false)}>
                  <span aria-hidden="true"><img src={crossicn}/></span>
                  </button>
               </div>
               <div>
                  <h5 class="modal-title">{errorMessage}</h5>
               </div>
               <div class="modal-body">
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="inputEmail4">Date</label>
                          <input type="date" class="form-control" id="inputScheduleDate" placeholder="DD/MM/YYYY" value={scheduleDate} onChange={e => setScheduleDate(e.target.value)}/>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputState">Time from</label>
                              <select id="inputTimeFrom" class="form-control" value={scheduleTimeFrom} onChange={e => setScheduleTimeFrom(e.target.value)}>
                                 {getFromRToTime()}
                              </select>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputState">Time to</label>
                              <select id="inputTimeTo" class="form-control" value={scheduleTimeTo} onChange={e => setScheduleTimeTo(e.target.value)}>
                                 {toArray}
                                 <option value={'06:30 PM'}>06:30 PM</option>
                              </select>
                        </div>
                        <div class="form-group col-md-2 stchmd">
                            <span>All day</span>
                            <label class="switch">
                              <input type="checkbox"/>
                              <span class="slider round"></span><span></span>
                            </label>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputState">Timezone</label>
                              <select id="inputTimeZone" class="form-control" value={timezone} onChange={e => setTimezone(e.target.value)}>
                                 <option value=''>Options</option>
                                 <option value="Hawaii">(GMT -10:00) Hawaii</option>
	                              <option value="Taiohae">(GMT -9:30) Taiohae</option>
                                 <option value="Alaska">(GMT -9:00) Alaska</option>
                                 <option value="PT">(GMT -8:00) Pacific Time</option>
                                 <option value="MT">(GMT -7:00) Mountain Time</option>
                                 <option value='CST'>(GMT -6:00) Central Time</option>
                                 <option value="ET">(GMT -5:00) Eastern Time</option>
                              </select>
                        </div>
                        <div class="form-group col-md-6">
                           
                        </div>
                      </div>
                      <button type="button" data-dismiss="modal" class="btn btn-theme-light btn-rounded sbmitfoot" onClick={()=>closeModal(true)}>Schedule</button>
               </div>
            </div>
         </div>
      </div>
      </Popup>)
      :
      ''
}
      </span> 
    );
}
export default ScheduleClassModal;