import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Tasks from '../../components/Tasks';
import { tasksFetchData, tasksFileUpload, tasksDetails, tasksDataUpload } from  './actions';
import { makeSelectStudentTaskStatusData } from './selectors';

const mapStateToProps = createStructuredSelector({
  tasksStatusData: makeSelectStudentTaskStatusData(),
 });

export function mapDispatchToProps(dispatch) {
  return {
    tasksFetchData: (data) => dispatch(tasksFetchData(data)),
    tasksFileUpload: (data) => dispatch(tasksFileUpload(data)),
    tasksDataUpload: (data) => dispatch(tasksDataUpload(data)),
    tasksDetails: (data) => dispatch(tasksDetails(data)),
    dispatch,
  };
}

const TasksContainer = connect(mapStateToProps,mapDispatchToProps)(Tasks);

export default TasksContainer;