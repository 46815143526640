import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Layout from '../../components/Layout';
import { teacherFetchData,logout,getAccessToken } from  '../Teacher/actions';
import { getTokenData } from  '../Tokens/actions';
import { lessonsDetails, lessonsCancel } from '../Lessons/actions';
import { tasksDetails, tasksFileUpload, tasksDataUpload } from  '../Tasks/actions';
import {
  makeSelectCoursesError,
  makeSelectCoursesData,
  makeSelectAdvData,
 } from '../Courses/selectors';
import { makeSelectTeacherSearchError,makeSelectTeacherSearchData, makeSelectTeacherId } from '../Teacher/selectors';
import { makeSelectTokenData } from '../Tokens/selectors';
import { makeSelectStudentLessonsError, makeSelectStudentLessonsData } from '../Lessons/selectors'
import { makeSelectStudentTaskData } from '../Tasks/selectors';
import { makeSelectMessagesData } from '../Messages/selectors';

const mapStateToProps = createStructuredSelector({
  studentDataError: makeSelectTeacherSearchError(),
  studentData: makeSelectTeacherSearchData(),
  coursesDataError: makeSelectCoursesError(),
  coursesData: makeSelectCoursesData(),
  advData: makeSelectAdvData(),
  lessonData: makeSelectStudentLessonsData(),
  lessanDataError: makeSelectStudentLessonsError(),
  tasksData: makeSelectStudentTaskData(),
  messagesData: makeSelectMessagesData(),
  teacherId: makeSelectTeacherId(),
  tokenData: makeSelectTokenData(),
 });

export function mapDispatchToProps(dispatch) {
  return {
    studentFetchData: (studentData) => {
      dispatch(teacherFetchData(studentData));
      dispatch(getAccessToken(studentData.accessToken));
    },
    lessonsCancel: (data) => dispatch(lessonsCancel(data)),
    lessonDetails: (data) => dispatch(lessonsDetails(data)),
    tasksFileUpload: (data) => dispatch(tasksFileUpload(data)),
    tasksDetails: (data) => dispatch(tasksDetails(data)),
    tasksDataUpload: (data) => dispatch(tasksDataUpload(data)),
    getTokenData: () => dispatch(getTokenData()),
    logout: () => dispatch(logout()),  
    dispatch,
  };
}

const LayoutContainer = connect(mapStateToProps,mapDispatchToProps)(Layout);

export default LayoutContainer;