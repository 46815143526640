var jtoken='';
export const getToken=(token)=>{
    jtoken = token ? token.accessToken :'';
}
export default jtoken;

export const getHeaders=()=>{
    var req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);
    var headers = req.getAllResponseHeaders().toLowerCase();
    headers = headers.split(/\n|\r|\r\n/g).reduce(function(a, b) {
        if (b.length) {
            var [ key, value ] = b.split(': ');
            a[key] = value;
        }
        return a;
    }, {});
    return headers;
}