import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { teacherReducer } from '../containers/Teacher/reducer';
import { coursesStudentReducer } from '../containers/Courses/reducer';
import { lessonsStudentReducer } from '../containers/Lessons/reducer';
import { tasksStudentReducer } from  '../containers/Tasks/reducer';
import { messagesReducer } from  '../containers/Messages/reducer';
import { ordersReducer } from '../containers/Orders/reducer';
import { tokenReducer } from '../containers/Tokens/reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    teacherReducer,
    coursesStudentReducer,
    lessonsStudentReducer,
    tasksStudentReducer,
    messagesReducer,
    ordersReducer,
    tokenReducer,
  });
 
  export default createRootReducer;
