import React, { Component } from 'react';
import music from '../../assets/images/music.png';
import NoData from '../NoData';

const OrderItems=(index, orders) => {
    const curriculumTitle = index.orders ? index.orders.curriculumTitle : '';
    const feeAmount = index.orders ? index.orders.amount : '';
    const dateOfOrder = index.orders ? index.orders.dateOfOrder : '';
    const timeFormat = new Date(dateOfOrder);
    return(
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-6 col-12">
            <div className="order-left">
                <img src={music} alt="music" className="img-fluid" />
            </div>
            <div className="order-right">
                <h3>{curriculumTitle}<span> <i className="fas fa-eye"></i></span></h3>
                <p>Teacher name &nbsp;&nbsp;<b>John Doe</b></p>
                <p className="price">${feeAmount}<span>/month</span></p>
                <p className="date">New Payment &nbsp;&nbsp;<b>@7 September 2020</b></p>
                <button className="btn btn-theme-light btn-rounded oderactve"><i className="fas fa-money-check"></i> Pay </button>
                <button className="btn btn-theme-light btn-rounded ordrbtn"> Cancel</button> 
            </div>
        </div>                         
    );
}


class Orders extends Component {
    constructor(props) {
      super(props);
      this.setActiveTabCompleted = this.setActiveTabCompleted.bind(this);
      this.setActiveTabInProgress = this.setActiveTabInProgress.bind(this);
      this.state = {
        orderPending : 'nav-link active',
        orderCompleted : 'nav-link',
        tabStaus: 1,
      }
    }
    componentDidMount() {
        this.props.ordersFetchData();
    }
    setActiveTabCompleted(e) {
        e.preventDefault();
        this.setState({
            orderPending: 'nav-link',
            orderCompleted: 'nav-link active',
            tabStaus: 2,
        }   
        );
      }
    setActiveTabInProgress(e) {
        e.preventDefault();
        this.setState({
            orderPending: 'nav-link active',
            orderCompleted: 'nav-link',
            tabStaus: 1
        });   
      }
    render() {
        var orderArray = [];
        const classStatus = this.state.tabStaus;
        var ordersCount = 0;
        if( classStatus===1){
            orderArray = this.props.ordersData ? this.props.ordersData : [];
            ordersCount= orderArray.length;
        } else {
            orderArray = this.props.ordersData ? this.props.ordersData : [];
        }
        
    return(
        <div className="dash-content flex-grow-1 ordertxtpge">
            <div className="row">
                <div className="col-md-8 col-7">
                    <h2 className="cus-heading mb-3">My Orders</h2>
                </div>
            </div>
            <section className="sliders mb-sm-4">
                <div className="row">
                    <div className="col-lg-12">
                           <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a className={this.state.orderPending} data-toggle="tab" href="#upcoming" onClick={this.setActiveTabInProgress}>Scheduled Orders &nbsp;&nbsp;<span className="badge badge-pill badge-warning">{ordersCount}</span></a>
                              </li>
                              <li className="nav-item">
                                <a className={this.state.orderCompleted} data-toggle="tab" href="#notscheduled" onClick={this.setActiveTabCompleted}>Completed Orders</a>
                              </li>
                           </ul>
                           <div className="tab-content">
                            <div className="tab-pane  active" id="completeorders">    
                                                <div className="row">
                                                    {orderArray.length > 0 ?
                                                        (orderArray.map((ordersData, index) => (
                                                                <OrderItems index={index} orders={ordersData} key={index}/>       
                                                        )))
                                                        :
                                                        <NoData apiCalled={this.props.ordersData} />
                                                    }
                                                </div>
                                           
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </div>
);
}
}
export default Orders;