import { createSelector } from 'reselect';

const teacherReducer = (state) => state.teacherReducer;

export const makeSelectTeacherId = () => createSelector(
  teacherReducer,
  (teacherstate) => {
	return teacherstate.teacherId;
  }
);
export const makeSelectTeacherSearchError = () => createSelector(
  teacherReducer,
  (teacherstate) => {
	return teacherstate.teacherSearchDataError;
  }
);
export const makeSelectTeacherSearchData = () => createSelector(
  teacherReducer,
  (teacherstate) => {
	return teacherstate.teacherSearchData;
  }
);
export const makeSelectTeacherSaveError = () => createSelector(
  teacherReducer,
  (teacherstate) => {
	return teacherstate.teacherSaveDataError;
  }
);
export const makeSelectTeacherSaveData = () => createSelector(
  teacherReducer,
  (teacherstate) => {
	return teacherstate.teacherSaveData;
  }
);
export const makeSelectStudentAttendanceData = () => createSelector(
  teacherReducer,
  (teacherstate) => {
	return teacherstate.studentAttendanceData;
  }
);
export const makeSelectAccesTokenData = () => createSelector(
  teacherReducer,
  (teacherstate) => {
	return teacherstate.accessToken;
  }
);