import {
  LESSONS_FETCH_DATA,
  LESSONS_FETCH_DATA_SUCCESS,
  LESSONS_FETCH_DATA_ERROR,
  LESSONS_FETCH_STATUS_SUCCESS,
  LESSONS_FETCH_STATUS_ERROR,
  LESSONS_CANCEL,
  LESSONS_CANCEL_SUCCESS,
  LESSONS_CANCEL_ERROR,
  LESSON_DETAILS,
  LESSON_DETAILS_SUCCESS,
  LESSON_DETAILS_ERROR,
  LESSON_SCHEDULE,
  LESSON_SCHEDULE_SUCCESS,
  LESSON_SCHEDULE_ERROR,
  LESSON_SCHEDULE_UPDATE,
  LESSON_SCHEDULE_UPDATE_SUCCESS,
  LESSON_SCHEDULE_UPDATE_ERROR,
} from './constants';

export function lessonsFetchData(data) {
  return {
    type: LESSONS_FETCH_DATA,
    data,
  };
}
export function lessonsFetchDataSuccess(data) {
  return {
    type: LESSONS_FETCH_DATA_SUCCESS,
	data,
  };
}
export function lessonsFetchDataError(error) {
  return {
    type: LESSONS_FETCH_DATA_ERROR,
	error,
  };
}
export function lessonsFetchStatusSuccess(data) {
  return {
    type:  LESSONS_FETCH_STATUS_SUCCESS,
	data,
  };
}
export function lessonsFetchStatusError(error) {
  return {
    type: LESSONS_FETCH_STATUS_ERROR,
	error,
  };
}
export function lessonsCancel(data) {
  return {
    type: LESSONS_CANCEL,
    data,
  };
}
export function lessonsCancelSuccess(data) {
  return {
    type: LESSONS_CANCEL_SUCCESS,
	  data,
  };
}
export function lessonsCancelError(error) {
  return {
    type: LESSONS_CANCEL_ERROR,
	  error,
  };
}
export function lessonsDetails(data) {
  return {
    type: LESSON_DETAILS,
    data,
  };
}
export function lessonsDetailsSuccess(data) {
  return {
    type: LESSON_DETAILS_SUCCESS,
	  data,
  };
}
export function lessonsDetailsError(error) {
  return {
    type: LESSON_DETAILS_ERROR,
	  error,
  };
}
export function lessonsSchedule(data) {
  return {
    type: LESSON_SCHEDULE,
    data,
  };
}
export function lessonsScheduleSuccess(data) {
  return {
    type: LESSON_SCHEDULE_SUCCESS,
	  data,
  };
}
export function lessonsScheduleError(error) {
  return {
    type: LESSON_SCHEDULE_ERROR,
	  error,
  };
}
export function lessonsScheduleUpdate(data) {
  return {
    type: LESSON_SCHEDULE_UPDATE,
    data,
  };
}
export function lessonsScheduleUpdateSuccess(data) {
  return {
    type: LESSON_SCHEDULE_UPDATE_SUCCESS,
	  data,
  };
}
export function lessonsScheduleUpdateError(error) {
  return {
    type: LESSON_SCHEDULE_UPDATE_ERROR,
	  error,
  };
}