import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LessonDetails from '../../components/LessonDetails';
import { lessonsCancel } from '../Lessons/actions';
import { makeSelectLessonDetails } from '../Lessons/selectors'

const mapStateToProps = createStructuredSelector({
  lessonDetails: makeSelectLessonDetails(),
});

export function mapDispatchToProps(dispatch) {
  return {
    lessonsCancel: (data) => dispatch(lessonsCancel(data)),
    dispatch,
  };
}

const LessonDetailsContainer = connect(mapStateToProps,mapDispatchToProps)(LessonDetails);

export default LessonDetailsContainer;