import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import { TEACHER_FETCH_DATA, API_END_POINT } from '../Teacher/constants';
import { makeSelectTeacherId } from '../Teacher/selectors';
import { MESSAGES_FETCH_DATA, MESSAGES_TEACHER, MESSAGES_SEND_DATA,MESSAGES_SEND_DATA_SUCCESS } from './constants';
import {
  messagesFetchDataSuccess,
  messagesFetchDataError,
  messagesTeacherSuccess,
  messagesTeacherError,
  messagesSendDataSuccess,
  messagesSendDataError
} from './actions';
import request from '../../utils/request';

// TODO uncomment studentId line and remove studentId hardcoding
export function* fetchMessagesData(payload) {
  //const studentId = payload.data ? payload.data.studentId: '';
  const studentId = yield select(makeSelectTeacherId());
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${studentId}/message`;
  
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(messagesFetchDataSuccess(response));
  } catch (error) {
    yield put(messagesFetchDataError(error));
  }
  
}

export function* fetchMessagesTeacherData(payload) {
  //const studentId = payload.data ? payload.data.studentId: '';
  const studentId = yield select(makeSelectTeacherId());
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${studentId}/student`;
  
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(messagesTeacherSuccess(response));
  } catch (error) {
    yield put(messagesTeacherError(error));
  }
}

export function* sendMessages(payload) {
  //const studentId = payload.data ? payload.data.studentId: '';
  const data = payload.data || '';
  const studentId = yield select(makeSelectTeacherId());
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${studentId}/message`;
  
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
	  yield put(messagesSendDataSuccess(response));
  } catch (error) {
    yield put(messagesSendDataError(error));
  }
  
}

export function* fetchMessagesDataDaemon() {
  yield takeLatest([TEACHER_FETCH_DATA,MESSAGES_FETCH_DATA,MESSAGES_SEND_DATA_SUCCESS], fetchMessagesData);
}
export function* fetchMessagesTeacherDaemon() {
  yield takeLatest(MESSAGES_TEACHER, fetchMessagesTeacherData);
}
export function* sendMessagesDaemon() {
  yield takeLatest(MESSAGES_SEND_DATA, sendMessages);
}

export default function* messagesSaga() {
  yield all([fetchMessagesDataDaemon(), fetchMessagesTeacherDaemon(), sendMessagesDaemon()]);
}