import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Lessons from '../../components/Lessons';
import { lessonsFetchData, lessonsCancel, lessonsDetails } from './actions';
import { makeSelectStudentLessonsError, makeSelectStudentLessonsData, makeSelectStudentLessonsStatus } from './selectors'

const mapStateToProps = createStructuredSelector({
  lessonData: makeSelectStudentLessonsData(),
  lessonStatus: makeSelectStudentLessonsStatus(),
  lessanDataError: makeSelectStudentLessonsError(),
 });

export function mapDispatchToProps(dispatch) {
  return {
    lessonsFetchData: (data) => dispatch(lessonsFetchData(data)),
    lessonsCancel: (data) => dispatch(lessonsCancel(data)),
    lessonDetails: (data) => dispatch(lessonsDetails(data)),
    dispatch,
  };
}

const LessonContainer = connect(mapStateToProps,mapDispatchToProps)(Lessons);

export default LessonContainer;