import React from 'react';
import { Link } from "react-router-dom";
import user from '../../assets/images/noimage.png';
import messageIcon from '../../assets/images/message.png';
import NoData from '../NoData';
 
const MessageItems=(index, messages) => {
    const message = index.messages ? index.messages.message : '';
    const messageTime = index.messages ? index.messages.time : '';
    const teacherName = index.messages ? index.messages.teacherName : '';
    const timeFormat = new Date(messageTime);
    return(
        <div className="cus-message d-flex mb-2">
            <div className="userimg mr-2">
                <img src={user} alt="user" className="img-fluid" />
            </div>
            <div className="message-detail">
                <div className="message-head d-flex align-items-center">
                    <h6 className="name mb-0">{teacherName}</h6>
                    <div className="time">{timeFormat.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</div> 
                </div>
                <div className="message">
                    <p>{message}</p>
                </div>
            </div>
        </div>
    );
}

const HomeMessages=(props) =>{
    var messagesArray = props.messagesData ? props.messagesData : [];
    var messagesCount = 0;
    if(Array.isArray(messagesArray)) {
        messagesCount = messagesArray.length;
        const sliceLength = (messagesArray.length > 3) ? 4 : messagesCount;
        messagesArray = messagesArray.slice(0, sliceLength);
    }
    const moreMessage = (messagesCount > 4) ? (messagesCount-4) : 0;
    return(
        <div className="card card-theme mb-4">
            <div className="card-header">
                <h4>Messages From Students</h4>
                <a href="#" className="viewall"><span className="badge badge-warning">{messagesCount}</span></a>
            </div>
            <div className="card-body p-2">
                <div className="card-massageinner">
                    {
                    messagesArray.length > 0 ?
                    (messagesArray.map((messagesData, index) => (
                            <MessageItems index={index} messages={messagesData} key={index}/>
                            )))
                            :
                            <NoData apiCalled={props.messagesData}/>
                    }
                </div>
               
                        <div className="text-center cusbtn">
                        <p className="text-dark mb-2">More...</p>
                        <Link to="/Messages" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><button class="btn btn-theme btn-rounded" data-toggle="modal" data-target="#messagepopup"> <img src={messageIcon} alt="message" class="img-fluid"/> Go to Messages</button></Link>
                    </div>
                    
            </div>
        </div>
        
);
}
export default HomeMessages;