import { subDomain } from '../../utils/util';
const DEF_API_END_POINT = 'https://8ot9km0u7j.execute-api.us-east-2.amazonaws.com';
export const TEACHER_FETCH_DATA = 'TEACHER_DATA';
export const TEACHER_FETCH_DATA_SUCCESS = 'TEACHER_DATA_SUCCESS';
export const TEACHER_FETCH_DATA_ERROR = 'TEACHER_DATA_ERROR';
export const TEACHER_SAVE_DATA = 'TEACHER_SAVE_DATA';
export const TEACHER_SAVE_DATA_SUCCESS = 'TEACHER_SAVE_DATA_SUCCESS';
export const TEACHER_SAVE_DATA_ERROR = 'TEACHER_SAVE_DATA_ERROR';
export const GET_TEACHER_ID = 'GET_TEACHER_ID';
export const UPLOAD_PROFILE_PHOTO = 'UPLOAD_PROFILE_PHOTO';
export const STUDENT_ATTENDANCE_DATA = 'STUDENT_ATTENDANCE_DATA';
export const STUDENT_ATTENDANCE_DATA_SUCCESS = 'STUDENT_ATTENDANCE_DATA_SUCCESS';
export const STUDENT_ATTENDANCE_DATA_ERROR = 'STUDENT_ATTENDANCE_DATA_ERROR';
export const STUDENT_ATTENDANCE_SAVE = 'STUDENT_ATTENDANCE_SAVE';
export const STUDENT_ATTENDANCE_SAVE_SUCCESS = 'STUDENT_ATTENDANCE_SAVE_SUCCESS';
export const STUDENT_ATTENDANCE_SAVE_ERROR = 'STUDENT_ATTENDANCE_SAVE_ERROR';
export const LOG_OUT = 'LOG_OUT';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const API_END_POINT = subDomain ? `https://${subDomain}.8ot9km0u7j.execute-api.us-east-2.amazonaws.co` : DEF_API_END_POINT;