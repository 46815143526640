import React, { Component } from 'react';
import StarRating from '../StarRating';
import photoshop from '../../assets/images/noimage.png';
import crossicn from '../../assets/images/crossicn.png';
import { format, startOfWeek, addDays, startOfMonth, endOfMonth, endOfWeek, isSameMonth, isSameDay, addMonths, subMonths } from "date-fns";
import Popup from '../Popup';
import './modalstyle.css';

class CalendarModal extends Component {
   constructor(props) {
     super(props);
     this.state={
      showModal:false,
      currentMonth: new Date(),
      selectedDate: new Date()
     }
   }
  
   openModal=()=>{
      this.setState({showModal:true});
    }
   closeModal=(boolSubmit)=>{
      this.props.selectDay(this.state.selectedDate);
      this.setState({showModal:false});
    }
   onDateClick = day => {
    this.setState({
      selectedDate: day
    });
   };

   nextMonth = () => {
    this.setState({
      currentMonth:  addMonths(this.state.currentMonth, 1)
    });
   };

   prevMonth = () => {
    this.setState({
      currentMonth:  subMonths(this.state.currentMonth, 1)
    });
   };
   renderHeader=()=>{
      const dateFormat = "MMMM yyyy";
      return(
         <thead>
            <tr>
               <th colspan="7">
                  <span class="btn-group">
                     <a class="btn" onClick={this.prevMonth}><i class="fas fa-angle-left"></i></a>
                     <a class="btn active">{ format(this.state.currentMonth, dateFormat)}</a>
                     <a class="btn" onClick={this.nextMonth}><i class="fas fa-angle-right"></i></a>
                  </span>
               </th>
            </tr>
            <tr>
               <th>S</th>
               <th>M</th>
               <th>T</th>
               <th>W</th>
               <th>T</th>
               <th>F</th>
               <th>S</th>
            </tr>
         </thead>
      )
   }
   renderDays=()=>{
      const { currentMonth, selectedDate } = this.state;
      const monthStart =  startOfMonth(currentMonth);
      const monthEnd =  endOfMonth(monthStart);
      const startDate =  startOfWeek(monthStart);
      const endDate =  endOfWeek(monthEnd);

      const dateFormat = "d";
      const rows = [];

      let days = [];
      let day = startDate;
      let formattedDate = "";
      let rowConfig = 0;

      while (day <= endDate) {
         for (let i = 0; i < 7; i++) {
            formattedDate =  format(day, dateFormat);
            const cloneDay = day;
            if(isSameDay(day, selectedDate)){
               rowConfig = 1;
            }
            days.push(
               <td className={isSameDay(day, selectedDate) ? 'selDate' : ''} key={day} onClick={() => this.onDateClick( cloneDay)}>
               {formattedDate}
               </td>
            );
            day =  addDays(day, 1);
         }
         rows.push(
         <tr key={day}>
            {days}
         </tr>
         );
         rowConfig = 0;
         days = [];
      }
      return <tbody>{rows}</tbody>;

   }
   render(){
      const origin = this.props.origin ? this.props.origin : 0;
   return (
     <span>
        { (origin===1) ?
        <button class="btn btn-theme-light btn-rounded wticnmb" onClick={()=>this.openModal()} data-toggle="modal" data-target="#feedbackmod"><i class="fa fa-star"></i> Feedback</button>
        :
        <button class="btn btn-theme-light btn-rounded cldrbtn" onClick={()=>this.openModal()} data-toggle="modal" data-target="#cldrbtn"><i class="fa fa-calendar-alt"></i> Calendar</button>
        }
        
     { this.state.showModal ?
     (<Popup>
        <div class="modal fade bgmdl" id="cldrbtn" tabindex="-1" role="dialog" aria-labelledby="cldrbtn" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.closeModal(false)}>
                  <span aria-hidden="true"><img src={crossicn}/></span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="cldritem">
                           <div class="card card-theme mb-4">
                               <table class="table-condensed">
                                    {this.renderHeader()}
                                    {this.renderDays()}
                                </table>
                            </div>
                         
                       </div>
               </div>
            </div>
         </div>
      </div>
      </Popup>)
      :
      ''
}
      </span> 
    );
}
}
export default CalendarModal;