import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import freeProfile from '../../assets/images/noimage.png';
import './styles.scss';

const uploadF=()=> {
   const uploadTag = document.getElementById("inputUpload");
   uploadTag.click();
}


class EditProfile extends Component {
    constructor(props) {
      super(props);
      this.onSetFileName = this.onSetFileName.bind(this);
      //this.setCallBack = this.setCallBack.bind(this);
      this.state = {
         parentName: this.props.studentData.parentName,
         timezone: this.props.studentData.timezone,
         school: this.props.studentData.school,
         grade: this.props.studentData.grade,
         address: this.props.studentData.address ? this.props.studentData.address: '',
         secContact: this.props.studentData.secContact,
         city: this.props.studentData.city,
         state: this.props.studentData.state,
         country:this.props.studentData.country,
         zip: this.props.studentData.zip ? this.props.studentData.zip: '',
         address2:this.props.studentData.address_line2 ? this.props.studentData.address_line2 : '',
         file:null,
         dob:this.props.studentData.dob,
         gender:this.props.studentData.gender,
         summary:this.props.studentData.summary,
         specialization:this.props.studentData.specialization,
         skills:this.props.studentData.skills,
      }
    }
   onSetFileName(e) {
      this.setState({file:e.target.files[0]},this.setCallBack);
      this.setCallBack();
      
   }
   setCallBack(){
      const fileD = this.state.file;
      if(fileD){
         this.uploadPhoto();
      }
   }
  
   handleSubmit(){
       const studentId = this.props.studentData.studentId;
       const parentName = document.getElementById('parentName').value;
       const timezone = document.getElementById('timeZone').value;
       const school = document.getElementById('college').value;
       const grade = document.getElementById('grade').value;
       const primaryContact = document.getElementById('primaryContact').value;
       const address = document.getElementById('address').value;
       const secContact = document.getElementById('secContact').value;
       const address_line2 = document.getElementById('address2').value;
       const city = document.getElementById('city').value;
       const state =  document.getElementById('state').value;
       const country = document.getElementById('country').value;
       const zip = document.getElementById('zip').value;
       const dob = document.getElementById('dob').value;
       const gender = document.getElementById('gender').value;
       const summary = document.getElementById('summary').value;
       const specialization = document.getElementById('specialization').value;
       const skills = document.getElementById('skills').value;
       const studentData = {
         studentId,
         parentName,
         timezone,
         school,
         grade,
         primaryContact,
         address,
         secContact,
         address_line2,
         city,
         state,
         country,
         zip,
         dob,
         gender,
         summary,
         specialization,
         skills
       };
       this.props.updateStudent(studentData);
       this.props.history.push("/Profile");
    }
    
    cancelValues(e){
      this.props.history.push("/Profile");
    }
    async uploadPhoto() {
      var fileInput =  document.getElementById('inputUpload'); 
      var filePath = fileInput.value;
      // Allowing file type 
      var allowedExtensions =  /(\.jpg|\.jpeg|\.png|\.gif)$/i; 
    
      if (!allowedExtensions.exec(filePath)) { 
         alert('Invalid file type'); 
         fileInput.value = ''; 
         return false; 
      }  
      const formData = new FormData();
      formData.append('avatar',this.state.file)
      this.props.uploadPhoto(formData);
   }  
    render() {
      const studentData = this.props.studentData ? this.props.studentData : '';
      const disabledValues = true;
      const artimeZones = moment.tz.zonesForCountry('US');
      const selectTitle = '';
    return(
      <div class="dash-content flex-grow-1">
         <div class="row">
            <div class="col-md-8 col-7">
               <h2 class="cus-heading mb-3">Edit Profile</h2>
            </div>
         </div>
         <div class="dash-content flex-grow-1">
                     <section class="sliders mb-sm-4">
                        <form id="studentForm">
                        <div class="row">
                           <div class="col-lg-2 col-md-12">
                              <div class="profile-information">
                                 <div class="profile-img">
                                    <img src={studentData.profileImage || freeProfile} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                                    <a href="javascript:void(0)" class="camera"  onClick={()=>uploadF()}><i class="fas fa-camera"></i></a>
                                    <input className="inputUploadStyle" id="inputUpload" type="file" onChange={this.onSetFileName} />
                                 </div>
                                 <h3>{studentData.firstName}</h3>
                                 <p>Roll No: {studentData.studentId}</p>
                                 <div class="brdrbtm"></div>
                                 <div class="prfletext">
                                    <h3></h3>
                                    <p></p>
                                 </div>
                                 <div class="brdrbtm"></div>
                                 <div class="prfletext">
                                    <h3></h3>
                                    <p></p>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-10 col-md-12">
                              <div class="card card-theme mb-4 prfle edit-profile">
                                 <div class="card-header"> 
                                    <h4>Personal Details</h4>
                                    <div class="side-buttons">
                                          <a href="#" class="save btn btn-theme btn-rounded" onClick={()=>this.handleSubmit()}>Save</a>
                                          <a href="#" class="cancel btn btn-theme-light btn-rounded" onClick={()=>this.cancelValues()}>Cancel</a>
                                    </div>
                                    
                                 </div>
                                 <div class="card-body p-1">
                                    <div class="cus-table table-responsive">
                                       <table class="table prfletable">
                                          <tbody>
                                             <tr>
                                                <td>
                                                   <span>Student Name</span>
                                                </td>
                                                <td>
                                                   <span>Parent Name</span>
                                                </td>
                                                <td>
                                                   <span>Gender</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="firstName" value={studentData.firstName} disabled={disabledValues}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="parentName" value={ this.state.parentName } onChange={e => this.setState({ parentName: e.target.value })}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group">
                                                        <select class="form-control" id="gender" value={this.state.gender} onChange={e => this.setState({ gender: e.target.value })}>
                                                            <option value=''>Options</option>
                                                            <option value='Male'>Male</option>
                                                            <option value='Female'>Female</option>
                                                            <option value='Others'>Others</option>
                                                        </select>
                                                    </div>
                                                </td>
                                             </tr>
                                             <tr class="spcng"></tr>
                                             <tr>
                                                <td>
                                                   <span>Time Zone</span>
                                                </td>
                                                <td>
                                                   <span>School/College</span>
                                                </td>
                                                <td>
                                                   <span>Grade</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <div class="form-group">
                                                        <select class="form-control" id="timeZone" value={this.state.timezone} onChange={e => this.setState({ timeZone: e.target.value })}>
                                                         <option value=''>Options</option>
                                                         <option value="Hawaii">(GMT -10:00) Hawaii</option>
	                                                      <option value="Taiohae">(GMT -9:30) Taiohae</option>
                                                         <option value="Alaska">(GMT -9:00) Alaska</option>
                                                         <option value="PT">(GMT -8:00) Pacific Time</option>
                                                         <option value="MT">(GMT -7:00) Mountain Time</option>
                                                         <option value='CST'>(GMT -6:00) Central Time</option>
                                                         <option value="ET">(GMT -5:00) Eastern Time</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                   <div class="form-group">
                                                        <input type="text" class="form-control" id="college" value={this.state.school} onChange={e => this.setState({ school: e.target.value })}/>
                                                    </div>
                                                </td>
                                                <td>
                                                   <div class="form-group">
                                                        <input type="text" class="form-control" id="grade" value={this.state.grade} onChange={e => this.setState({ grade: e.target.value })}/>
                                                    </div>
                                                </td>
                                             </tr>
                                             <tr class="spcng"></tr>
                                             <tr>
                                                <td>
                                                   <span>Date of Birth</span>
                                                </td>
                                                <td>
                                                   <span>Login Email</span>
                                                </td>
                                                <td>
                                                   <span>Password</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <div class="input-group">
                                                        <input type="date" class="form-control" id="dob" name="birthday" value={moment(this.state.dob).format('YYYY-MM-DD')} onChange={e => this.setState({ dob: e.target.value })}/>
                                                    </div>
                                                </td>
                                                <td>
                                                   <div class="form-group">
                                                        <input type="email" class="form-control" id="email" value={studentData.email} disabled={disabledValues}/>
                                                    </div>
                                                </td>
                                                <td>
                                                   <div class="input-group">
                                                        <input type="password" id="pswrd" class="form-control" placeholder=""/>
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text" id="validationTooltipUsernamePrepend"><i class="far fa-eye"></i></span>
                                                        </div>
                                                    </div>
                                                </td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div class="card card-theme mb-4 prfle edit-profile"> 
                                 <div class="card-header">
                                    <h4>Contact Details</h4>
                                 </div>
                                 <div class="card-body p-1">
                                    <div class="cus-table table-responsive">
                                       <table class="table prfletable">
                                          <tbody>
                                             <tr>
                                                <td>
                                                   <span>Address Line 1</span>
                                                </td>
                                                <td>
                                                   <span>Primary Contact Number</span>
                                                </td>
                                                <td>
                                                   <span>Secondary Contact Number</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="address" value={this.state.address} onChange={e => this.setState({ address: e.target.value })}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="primaryContact" value={studentData.primaryContact} disabled={disabledValues}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="secContact" value={this.state.secContact} onChange={e => this.setState({ secContact: e.target.value })}/>
                                                    </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <span>Address Line 2</span>
                                                </td>
                                                <td>
                                                   <span>City</span>
                                                </td>
                                                <td>
                                                   <span>State</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                <div class="form-group">
                                                   <input type="text" class="form-control" id="address2" value={this.state.address_line2} onChange={e => this.setState({ address_line2: e.target.value })}/>
                                                </div>
                                                </td>
                                                <td>
                                                   <div class="form-group">
                                                      <input type="text" class="form-control" id="city" value={this.state.city} onChange={e => this.setState({ city: e.target.value })}/>
                                                   </div>
                                                </td>
                                                <td>
                                                <div class="form-group">
                                                   <input type="text" class="form-control" id="state" value={this.state.state} onChange={e => this.setState({ state: e.target.value })}/>
                                                </div>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                   <span>Zip</span>
                                                </td>
                                                <td>
                                                   <span>Country</span>
                                                </td>
                                                <td>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                <div class="form-group">
                                                        <input type="text" class="form-control" id="zip" value={this.state.zip} onChange={e => this.setState({ zip: e.target.value })}/>
                                                    </div>
                                                </td>
                                                <td>
                                                <div class="form-group">
                                                        <input type="text" class="form-control" id="country" value={this.state.country} onChange={e => this.setState({ country: e.target.value })}/>
                                                    </div>
                                                </td>
                                                <td></td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div class="card card-theme mb-4 prfle edit-profile"> 
                                 <div class="card-header">
                                    <h4>Professional Details</h4>
                                 </div>
                                 <div class="card-body p-1">
                                    <div class="cus-table table-responsive">
                                       <table class="table prfletable">
                                          <tbody>
                                             <tr>
                                                <td>
                                                   <span>Summary</span>
                                                </td>
                                                <td>
                                                   <span>Specialization</span>
                                                </td>
                                                <td>
                                                   <span>Skills</span>
                                                </td>
                                             </tr>
                                             <tr>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="textarea" rows="3" class="form-control" id="summary" value={this.state.summary} onChange={e => this.setState({ summary: e.target.value })}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="textarea" rows="3" class="form-control" id="specialization" value={studentData.specialization} disabled={disabledValues}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-group">
                                                        <input type="textarea" rows="3" class="form-control" id="skills" value={this.state.skills} onChange={e => this.setState({ skills: e.target.value })}/>
                                                    </div>
                                                </td>
                                             </tr>
                                            
                                             
                                            
                                             
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </form>
                     </section>
                  </div>
      </div>
   );
}
}
export default EditProfile;