import { all, call, put, takeLatest,select } from 'redux-saga/effects';
import { ORDERS_FETCH_DATA, API_END_POINT } from './constants';
import { ordersFetchDataSuccess, ordersFetchDataError } from './actions';
import { makeSelectTeacherId } from '../Teacher/selectors';
import request, {headers} from '../../utils/request';

// TODO uncomment studentId line and remove studentId hardcoding
export function* fetchOrdersData(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/student/${studentId}/order`;
  
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(ordersFetchDataSuccess(response));
  } catch (error) {
    yield put(ordersFetchDataError(error));
  }
  
}

export function* fetchOrdersDataDaemon() {
  yield takeLatest(ORDERS_FETCH_DATA, fetchOrdersData);
}

export default function* ordersSaga() {
  yield all([fetchOrdersDataDaemon()]);
}