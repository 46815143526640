import React, { Component } from 'react';
import photoshop from '../../assets/images/photoshop.png';
import crossicn from '../../assets/images/crossicn.png';
import NoData from '../NoData';
import Popup from '../Popup';
import './styles.scss';

class TaskViewModal extends Component{
   constructor(props) {
       super(props);
       this.openModal = this.openModal.bind(this);
       this.state ={
           showModal:true,
         };
   }
   openModal=()=>{
        this.setState({showModal:true});
        const modalDivClose = document.getElementById('taskView');
        modalDivClose.style.display="block";
    }
    closeModal=()=>{
        const modalDivClose = document.getElementById('taskView');
        modalDivClose.style.display="none";
    }
    
    
render(){
    const { tasks } = this.props;
    const { attachmentLink, description, message, studentName, taskTitle } = tasks;
    const submittedDate = this.props.tasks ? this.props.tasks.submittedDate : ''; 
    const timeFormat = new Date(submittedDate);
    const defaultTime = new Date(null);
    var displayTime = '';
    if((defaultTime.getTime())!==(timeFormat.getTime())) {
        displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
    }
    return(
      <span>
          <a href="#" class="btn btn-theme-light btn-rounded opnatndnce" onClick={()=>this.openModal()}><i class="fa fa-eye"></i></a>
          <Popup>
            <div class="rightbatch btchrgt" id="taskView">
                <div class="rightbatchheader"> 
                    <div class="row">
                        <div class="col-md-3">
                            <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                        </div>  
                        <div class="col-md-7">
                            <h2>{taskTitle}</h2>
                            <p class="mb-0">Assignment 1</p>
                        </div>
                        <div class="col-md-2">
                            <a href="#" class="crsncnbtch" onClick={()=>this.closeModal()}><img src={crossicn}/></a>
                        </div>
                    </div> 
                </div>

                    <div class="rightbatchbody">
                        <p>{description}</p>
                        <p>{message}</p>
                        <hr class="whitebrdr"/>
                        <p>Submitted Date : { displayTime }</p>
                        <p>Student Name : {studentName}</p>
                        <p>Attachment Link : {attachmentLink}</p>
                    </div>
            </div>
        </Popup>
      </span>
    );
}
}
export default TaskViewModal;
