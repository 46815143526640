import React, { Component } from 'react';
import photoshop from '../../assets/images/noimage.png';
import Popup from '../Popup';
import './styles.scss';

const uploadF=()=> {
   const uploadTag = document.getElementById("inputUpload");
   uploadTag.click();
}

export class FileUploadModal extends Component{
   constructor(props) {
       super(props);
       this.onChange = this.onChange.bind(this);
       this.openModal = this.openModal.bind(this);
       this.closeModal = this.closeModal.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this);
       this.state ={
           file:null,
           showModal:false,
         };
   }
   openModal=()=>{
      this.setState({showModal:true});
    }
   onChange(e) {
       this.setState({file:e.target.files[0]});
   }
   closeModal(boolSubmit){
      if(boolSubmit){
         this.handleSubmit();
      }
      this.setState({showModal:false});
    }
   async handleSubmit() {
      //e.preventDefault();
      const comments = document.getElementById('exampleFormControlTextarea1').value;
      const rating = document.getElementById('exampleFormControlSelect1').value;
      const assigntaskId = this.props.taskId || '';
      
      const taskData = { assigntaskId, rating }
      this.props.tasksDataUpload(taskData);
   }  

render(){
   const classIcon = this.props.tasks ? this.props.tasks.classIcon : '';
   const submissionDueDate = this.props.tasks ? this.props.tasks.submissionDueDate : '';
   const timeFormat = new Date(submissionDueDate);
   const origin = this.props.origin ? this.props.origin : 0;
    return(
      <span>
      { (origin===1) ?
         <a href="#" class="btn btn-theme-light btn-rounded" data-toggle="modal" data-target="#submitmd" onClick={()=>this.openModal()}><i class="fa fa-edit"></i></a>
         :
         <a href="#" class="btn btn-theme-light btn-rounded" data-toggle="modal" data-target="#submitmd" onClick={()=>this.openModal()}><i class="fa fa-edit"></i></a>
         }
       
      { this.state.showModal ?
      (<Popup>
          <div class="modal fade bgmdl" id="submitmd" tabindex="-1" role="dialog" aria-labelledby="submitmd" aria-hidden="true">
         <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Task Assessment</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.closeModal(false)}>
                  <span aria-hidden="true"><img src="images/crossicn.png"/></span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="course d-flex align-items-center">
                     <img src={photoshop} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                     <p class="mb-0">Mark Wyne</p>
                  </div>
                  <p class="asnmnt">Lesson Description</p>
                 
               </div>
               <div class="modal-footer">
                     <div class="form-group">
                    <label htmlfor="exampleFormControlSelect1">Ratings</label>
                    <select class="form-control" id="exampleFormControlSelect1">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </select>
                  </div>
                  <div class="form-group">
                     <textarea class="form-control" id="exampleFormControlTextarea1" rows="3">Comments</textarea>
                  </div>
                  <button class="btn btn-theme-light btn-rounded sbmitfoot" data-dismiss="modal" onClick={() => this.closeModal(true)}>Close Task</button>
               </div>
            </div>
         </div>
      </div>
     </Popup>)
     :
      ''
   }
   </span>
    );
}
}