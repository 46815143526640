import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';
import { TEACHER_FETCH_DATA, API_END_POINT } from '../Teacher/constants';
import { LESSONS_FETCH_DATA, LESSONS_CANCEL, LESSON_DETAILS, LESSON_SCHEDULE, LESSON_SCHEDULE_UPDATE,LESSONS_CANCEL_SUCCESS,LESSON_SCHEDULE_UPDATE_SUCCESS } from './constants';
import { lessonsFetchDataSuccess,
  lessonsFetchDataError,
  lessonsFetchStatusSuccess,
  lessonsFetchStatusError,
  lessonsSchedule,
  lessonsCancelError,
  lessonsDetailsSuccess,
  lessonsDetailsError,
  lessonsScheduleSuccess,
  lessonsScheduleError,
  lessonsScheduleUpdateSuccess,
  lessonsScheduleUpdateError,
} from './actions';
import {makeSelectLessonDetailsFetch} from './selectors';
import { makeSelectTeacherId } from '../Teacher/selectors';
import request, { headers } from '../../utils/request';

export function* fetchLessonsData(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${studentId}/lesson/upcoming`;
  
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(lessonsFetchDataSuccess(response));
  } catch (error) {
    yield put(lessonsFetchDataError(error));
  }
  
}

export function* fetchLessonsStatus(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURLUpcoming = `${API_END_POINT}/teacher/${studentId}/lesson/upcoming`;
  const requestURLNotScheduled = `${API_END_POINT}/teacher/${studentId}/lesson/pending`;
  const requestURLCompleted = `${API_END_POINT}/teacher/${studentId}/lesson/completed`;
  try{
    const responseUpcoming = yield call(request, requestURLUpcoming, { timeout: DEFAULT_TIMEOUT, headers });
    const responseNotScheduled = yield call(request, requestURLNotScheduled, { timeout: DEFAULT_TIMEOUT, headers });
    const responseCompleted = yield call(request, requestURLCompleted, { timeout: DEFAULT_TIMEOUT, headers });
    const response = {
      upcoming: responseUpcoming || [],
      completed: responseCompleted || [],
      notScheduled: responseNotScheduled || [],
    }
	  yield put(lessonsFetchStatusSuccess(response));
  } catch (error) {
    yield put(lessonsFetchStatusError(error));
  }
}

export function* lessonsCancel(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const scheduleId = payload.data ? payload.data.scheduleId: '';
  const getSchedulePayload = payload.data ? payload.data.getSchedulePayload: '';
  //const refreshIndex = payload.data ? payload.data.refreshIndex: '';
  const data = {
    teacherScheduleId: scheduleId,
  };
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/schedule/remove`;
  const res = {};
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
    if(response){
      yield put(lessonsSchedule(getSchedulePayload));
    }
  } catch (error) {
    yield put(lessonsCancelError(error));
  }
  
}

export function* lessonDetails(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const lessonData =  yield select(makeSelectLessonDetailsFetch());
  const lessonId = lessonData ? lessonData.lessonId: '';
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${studentId}/lesson/${lessonId}`;
  try{
    const response = yield call(request, requestURL, { timeout: DEFAULT_TIMEOUT, headers });
	  yield put(lessonsDetailsSuccess(response));
  } catch (error) {
    yield put(lessonsDetailsError(error));
  }
  
}

export function* lessonSchedule(payload) {
  const data = payload.data ? payload.data : '';
  const teacherId = yield select(makeSelectTeacherId());
  const DEFAULT_TIMEOUT = 20000;
  const requestURL = `${API_END_POINT}/teacher/${teacherId}/schedule`;
  try{
    const response = yield call(request, requestURL, {  method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
	  yield put(lessonsScheduleSuccess(response));
  } catch (error) {
    yield put(lessonsScheduleError(error));
  }
}

export function* scheduleUpdate(payload) {
  const studentId = yield select(makeSelectTeacherId());
  const data = payload.data || '';
  const refreshIndex = payload.data.refreshIndex || '';
  const requestURL = `${API_END_POINT}/teacher/${studentId}/schedule/create`;
  const DEFAULT_TIMEOUT = 20000;
  const res = {};
  var getSchedulePayload = '';
  if(payload){
    var tday = payload.data.selectedDate ;
    const fromDate = moment(tday).startOf('week').format();
    const toDate = moment(tday).endOf('week').format();
    getSchedulePayload = {
       teacherId: studentId,
       startdate: fromDate,
       enddate: toDate
    }
  }
  try{
    const response = yield call(request, requestURL, { method: 'POST', data, timeout: DEFAULT_TIMEOUT, headers });
    /*if(refreshIndex===3){
      yield call(lessonDetails);
    }else if(refreshIndex===2){
      yield call(fetchLessonsStatus);
    }else{
      yield call(fetchLessonsData);
    }*/
    if(response){
      yield put(lessonsSchedule(getSchedulePayload));
    }
  } catch (error) {
    yield put(lessonsScheduleUpdateError(error));
  }
}

export function* fetchLessonsDataDaemon() {
  yield takeLatest(TEACHER_FETCH_DATA, fetchLessonsData);
}
export function* fetchLessonsStatusDaemon() {
  yield takeLatest(LESSONS_FETCH_DATA, fetchLessonsStatus);
}
export function* lessonsCancelDaemon() {
  yield takeLatest(LESSONS_CANCEL, lessonsCancel);
}
export function* lessonDetailsDaemon() {
  yield takeLatest(LESSON_DETAILS,lessonDetails);
}
export function* lessonScheduleDaemon() {
  yield takeLatest(LESSON_SCHEDULE, lessonSchedule);
}
export function* scheduleUpdateDaemon() {
  yield takeLatest(LESSON_SCHEDULE_UPDATE, scheduleUpdate);
}

export default function* lessonsSaga() {
  yield all([fetchLessonsDataDaemon(),
    fetchLessonsStatusDaemon(),
    lessonsCancelDaemon(),
    lessonDetailsDaemon(),
    lessonScheduleDaemon(),
    scheduleUpdateDaemon(),
  ]);
}