import React, { Component } from 'react';
import noImage from '../../assets/images/detailed_page_no_image.PNG';
import crossIcn from '../../assets/images/crossicn.png';
import NoData from '../NoData';
import './styles.scss';

class AttendanceModal extends Component{
   constructor(props) {
       super(props);
       this.openModal = this.openModal.bind(this);
       this.state ={
           showModal:true,
         };
   }
   openModal=()=>{
      this.setState({showModal:true});
    }
    componentDidMount(){
        const data = {}
        this.props.studentAttendanceData(data);
    }
    closeModal=()=>{
        const modalDivClose = document.getElementById('atn');
        modalDivClose.style.display="none";
        const chkAll = document.getElementsByName('students');
        chkAll.forEach(st => st.checked = false);
        const stAll = document.getElementsByName('stAll');
        stAll.checked = false;
    }
    handleAllChecked = (event) => {
        const chkAll = document.getElementsByName('students');
        chkAll.forEach(st => st.checked = event.target.checked);
        
    }
    handleSubmit = (event) =>{
        const students = [];
        const chkAll = document.getElementsByName('students');
        chkAll.forEach(st => {
            if(st.checked){
                students.push({'studentId':st.value})
            }
        }
        );
        this.props.studentAttendanceSave(students);
        this.closeModal();
    }
render(){
    const studentsName = this.props.studentAttendanceDetails ? this.props.studentAttendanceDetails: [];
    const noOfStudents = this.props.studentAttendanceDetails ? this.props.studentAttendanceDetails.length: 0;
    const studentProfileImage = this.props.studentAttendanceDetails ? this.props.studentAttendanceDetails.studentProfileImage : '';
    const batchName = this.props.batch ? this.props.batch : '';
    const classIndex = this.props.classIndex ? this.props.classIndex : '';
    const classIcon = this.props.classIcon ? this.props.classIcon : '';
    return(
        <div class="rightbatch" id="atn">
                    <div class="rightbatchheader"> 
                        <div class="row">
                           <div class="col-md-3">
                               <img src={classIcon || noImage} alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
                           </div>  
                           <div class="col-md-7">
                               <h2>{batchName.substr(0,20)}...</h2>
                               <p class="mb-0">{classIndex}</p>
                           </div>
                           <div class="col-md-2">
                               <a href="#" class="crsncnbtch" onClick={()=>this.closeModal()}><img src={crossIcn}/></a>
                           </div>
                        </div> 
                    </div>
                    <div class="rightbatchheader1"> 
                        <div class="row">
                           <div class="col-md-6">
                              <p>Student({noOfStudents})</p>
                           </div>  
                           <div class="col-md-6">
                               <input type="checkbox" name="stAll" onClick={this.handleAllChecked}  value="checkedall"/>
                               <label htmlFor="stAll"> Select All</label><br></br>
                           </div>
                        </div> 
                        <hr/>
                    </div>
                    <div class="rightbatchbody">
                    {
                                    studentsName.length > 0 ?
                                       (studentsName.map((studentData) => (
                                            <p>
                                                <input type="checkbox" name="students" value={studentData.studentScheduleId}/>
                                                <label htmlFor="students"><span>{studentProfileImage}</span> {studentData.studentName || ''}</label>
                                            </p>             
                                       )))
                                          :
                                       <NoData apiCalled={this.props.studentAttendanceDetails}/>                     
                                 }
                       
                        <hr class="whitebrdr"/>
                        <button type="button" class="btn btn-theme-light btn-rounded btnrsnd" onClick={()=>this.handleSubmit()}>Mark Attendance</button>
                    </div>
                </div>
    );
}
}
export default AttendanceModal;
