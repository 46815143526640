import React, { Component } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import CalendarModal from '../CalendarModal';
import ScheduleClassModal from '../ScheduleClassModal';
import ScheduleDeleteModal from '../ScheduleDeleteModal';

class Schedule extends Component {
    constructor(props) {
      super(props);
      this.selectDay = this.selectDay.bind(this);
      this.state = {
         modalIsOpen : false,
         selectedDayForWeek: moment(),
      }
    }
    componentDidMount(){
      this.getScheduleDetails();
    }
    selectDay(dayIndex){
      this.setState({selectedDayForWeek:dayIndex},this.getScheduleDetails);
      this.getScheduleDetails(origin);
    }
    getScheduleDetails(){
      const teacherId = this.props.teacherId ? this.props.teacherId : '';
      var tday = this.state.selectedDayForWeek;
      const fromDate = moment(tday).startOf('week').format();
      const toDate = moment(tday).endOf('week').format();
      const payload = {
         teacherId: teacherId,
         startdate: fromDate,
         enddate: toDate
      }
      this.props.lessonsSchedule(payload);
    }
    renderHeader(from_date,to_date){
      var day = from_date;
      var days=[];
      while(day <= to_date){
         days.push(
            <div class="daybox">
               <p><b>{day.format("D")}</b><span>{day.format("ddd")}</span></p>
            </div>
         )
         day=moment(day).add(1,'days');
      }
       return(
         <>
         {days}
         </>  
       );
    }
    renderCells(){

    }

render() {
   var selectedSession = '';
   const teacherId = this.props.teacherId ? this.props.teacherId : '';
   const tokenData = this.props.tokenData ? this.props.tokenData : [];
   const halfHour = [{t:'06:00',noon:'AM'},{t:'06:30',noon:'AM'},{t:'07:00',noon:'AM'},{t:'07:30',noon:'AM'},{t:'08:00',noon:'AM'},{t:'08:30',noon:'AM'},{t:'09:00',noon:'AM'},{t:'09:30',noon:'AM'},{t:'10:00',noon:'AM'},{t:'10:30',noon:'AM'},{t:'11:00',noon:'AM'},{t:'11:30',noon:'AM'},{t:'12:00',noon:'PM'},{t:'12:30',noon:'PM'},{t:'01:00',noon:'PM'},{t:'01:30',noon:'PM'},{t:'02:00',noon:'PM'},{t:'02:30',noon:'PM'},{t:'03:00',noon:' PM'},{t:'03:30',noon:' PM'},{t:'04:00',noon:'PM'},{t:'04:30',noon:'PM'},{t:'05:00',noon:'PM'},{t:'05:30',noon:'PM'},{t:'06:00',noon:'PM'}]
   const oneHour = [{t:'06:00',noon:'AM'},{t:'07:00',noon:'AM'},{t:'08:00',noon:'AM'},{t:'09:00',noon:'AM'},{t:'10:00',noon:'AM'},{t:'11:00',noon:'AM'},{t:'12:00',noon:'PM'},{t:'01:00',noon:'PM'},{t:'02:00',noon:'PM'},{t:'03:00',noon:'PM'},{t:'04:00',noon:'PM'},{t:'05:00',noon:'PM'},{t:'06:00',noon:'PM'}];
   if(tokenData[3] && tokenData[3].value === '30'){
      selectedSession = halfHour;
   }else{
      selectedSession = oneHour;
   }
   var today=this.state.selectedDayForWeek;
   const from_date = moment(today).startOf('week');
   const to_date = moment(today).endOf('week');
   var day = from_date;
   var days=[];
   while(day <= to_date){
      days.push(day.format("D"));
      day=moment(day).add(1,'days');
   }
   const rowMax=selectedSession.length;
   const colMax=days.length;
   var scheduleArray= [];
   const temp1 = this.props.scheduleDetails[16] ? this.props.scheduleDetails[16].scheduleStartdate : '';
   const temp=temp1.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'long'});;
   console.log('Dat',moment(temp).format('D'));
   console.log('Hou',moment(temp).format('hh:mm'));
   console.log('Noot',moment(temp).format('A'));
   const scheduled = this.props.scheduleDetails ? this.props.scheduleDetails : [];
   for(var i=0;i<rowMax;i++){
      scheduleArray[i]=[];
   }
   for(var i=0;i<rowMax;i++){
      for(var j=0;j<colMax;j++){
         scheduled.length > 0 && scheduled.forEach(item => {
            var tempTime = item.scheduleStartdate;
            if((moment(tempTime).format('D')===days[j])&&(moment(tempTime).format('hh:mm')===selectedSession[i].t)&&(moment(tempTime).format('A')===selectedSession[i].noon)){
               scheduleArray[i][j]=item ;
            }
         });
        
      }
   }
   var cellsRowArray=[];
   for(var i=0;i<rowMax;i++){
      var cellsColArray=[];
      for(var j=0;j<colMax;j++){
         var tSlot=scheduleArray[i][j];
         var clsname='clndrbx';
         if(tSlot){
            if(tSlot.active==='Booked'){
               cellsColArray.push(
                  <ScheduleDeleteModal origin={0} teacherId={teacherId} lessonsDelete={this.props.lessonsCancel} scheduleId={tSlot.teacherScheduleId} teacherId={teacherId} selectedDate={this.state.selectedDayForWeek}/>
               )
            }else {
               if(tSlot.active==='Active'){
                  clsname='clndrbx clbrdr';
               }
               cellsColArray.push(
                  <div class={clsname}>
                                   
                  </div>
               )
            }
         }else{
            cellsColArray.push(
               <div class={clsname}>
                                
               </div>
            )
         }
         
      }
      cellsRowArray.push(
      <div class="divbx">{cellsColArray}</div>
      )
   }
   return(
      <div class="dash-content flex-grow-1 clndrpge">
      <div class="row">
      <div class="col-md-6">
                  <Link to="/LessonsSchedule" style={{ cursor: 'default', color: 'inherit', textDecoration: 'inherit'}}><button class="btn btn-theme-light btn-rounded filters mb20"><i class="fa fa-calendar-alt"></i> Lessons Schedule</button></Link>
              </div>
      <div class="col-md-3">
                  <CalendarModal origin={0} selectDay={this.selectDay}/>
              </div>
              
              <div class="col-md-3">
                  <ScheduleClassModal selectDay={this.selectDay} origin={0} teacherId={teacherId} teacherScheduleUpdate={this.props.teacherScheduleUpdate} selectedSession={selectedSession}/>
              </div>
          </div>
      <div class="row">
      <div class="col-md-12 cldrmd">
      <h2 class="cus-heading mb-3">Training Calendar</h2>
      
      <div class="calenderbox">
          <div class="calenderheader">
              <div class="daybox frstday">
                 
              </div>
              {this.renderHeader(from_date,to_date)}
          </div>
          <div class="calenderbody">
              <div class="row">
                  <div class="col-md-1">
                  <div class="leftbar">
                     {
                        selectedSession.length > 0 ?
                            (selectedSession.map((sessionData, index) => (
                            <p  key={index}>{sessionData.t} {sessionData.noon}</p>
                            )))
                            :
                            ''
                     }
                      </div>
                  </div>
                  <div class="col-md-11">
                      <div class="rightbar">
                     {cellsRowArray}
                      </div>
                  </div>
                  
              </div>
          </div>
          
      </div>
  </div>
  </div>
  </div>
);
}
}
export default Schedule;