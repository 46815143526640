import React from 'react';
import { Route, Switch } from 'react-router';
import SearchBar from '../SearchBar';
import DashBoard from '../DashBoard';
import Tasks from '../../containers/Tasks';
import Courses from '../../containers/Courses';
import Lessons from '../../containers/Lessons';
import Orders from '../../containers/Orders';
import LessonDetails from '../../containers/LessonDetails';
import TaskDetails from '../../containers/TaskDetails';
import CourseDetails from '../../containers/CourseDetails';
import HelpSupport from '../Help';
import Profile from '../Profile';
import EditProfile from '../../containers/EditProfile';
import Footer from '../Footer';
import Messages from '../../containers/Messages';
import Settings from '../Settings';
import Schedule from '../../containers/Schedule';
import LessonsSchedule from '../../containers/LessonsSchedule';


const RightPane=(props) =>{
    const studentData = props.studentData ? props.studentData : '';
    const tokenData = props.tokenData ? props.tokenData : '';
    const studentId = props.teacherId ? props.teacherId : '';
    return(
        <>
            <SearchBar logout={props.logout} studentId={studentId} studentData={studentData} tokenData={tokenData} studentFetchData={props.studentFetchData} logout={props.logout} getAccessToken={props.getAccessToken}/>
                <Switch>
                    <Route exact path="/" component={() =>  <DashBoard {...props} />} />
                    <Route path="/Lessons" component={() => <Lessons {...props} />} />
                    <Route path="/Tasks" component={() => <Tasks {...props} />} />
                    <Route path="/Courses" component={() => <Courses {...props} />} />
                    <Route path="/Messages" component={() => <Messages {...props} />} />
                    <Route path="/Orders" component={() => <Orders {...props} />} />
                    <Route path="/Profile" component={() => <Profile {...props} />} />
                    <Route path="/LessonDetails" component={() => <LessonDetails />} />
                    <Route path="/TaskDetails" component={() => <TaskDetails />} />
                    <Route path="/HelpSupport" component={() => <HelpSupport {...props} />} />
                    <Route path="/EditProfile" component={() => <EditProfile {...props} />} />
                    <Route path="/CourseDetails" component={() => <CourseDetails/>} />
                    <Route path="/Schedule" component={() => <Schedule {...props}/>} />
                    <Route path="/LessonsSchedule" component={() => <LessonsSchedule {...props}/>} />
                    <Route path="/Settings" component={() => <Settings {...props}/>} />
                </Switch>
            <Footer />
            
        </>
);
}
export default RightPane;