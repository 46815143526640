import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LessonsSchedule from '../../components/LessonsSchedule';
import { lessonsSchedule,lessonsCancel } from '../Lessons/actions';
import { makeSelectStudentLessonSchedule } from '../Lessons/selectors'
import { makeSelectTeacherId } from '../Teacher/selectors';
import { makeSelectTokenData } from '../Tokens/selectors';

const mapStateToProps = createStructuredSelector({
  scheduleDetails: makeSelectStudentLessonSchedule(),
  teacherId: makeSelectTeacherId(),
  tokenData: makeSelectTokenData(),
});

export function mapDispatchToProps(dispatch) {
  return {
    lessonsSchedule: (data) => dispatch(lessonsSchedule(data)),
    lessonsCancel: (data) => dispatch(lessonsCancel(data)),
    dispatch,
  };
}

const LessonScheduleIndex = connect(mapStateToProps,mapDispatchToProps)(LessonsSchedule);

export default LessonScheduleIndex;