import React from 'react';
import LeftPane from '../Leftpane';
import RightPane from '../Rightpane';
import { getHeaderValue } from '../../utils/request'
import './styles.css';
import './studentStyles.css';

class Layout extends React.Component {
    constructor(props) {
      super(props);
      this.goodBye = this.goodBye.bind(this);
    }
    componentDidMount() {
        const search = window.location.search;
        const params = search.split('?');
        const studentId = params[1] || '';
        const token = studentId ? studentId.split('=') : false;
        if(token){
            const payload = getHeaderValue();
            if(payload){
                (this.props.studentFetchData(payload));
            }
        }
        window.addEventListener("unload",this.goodBye); 
        this.props.getTokenData();
    }
    goodBye=()=>{
        this.props.logout();
    }
    componentWillUnmount(){
        window.removeEventListener("unload",this.goodBye);
    }
    removeHideLeft=()=>{
        const leftdiv = document.getElementById("leftSBar");
        const bodydiv = document.getElementById("mainBody");
        const dashdiv = document.getElementById("dashDiv");
        const rightDiv = document.getElementById("rightDiv");
        const togBtn = document.getElementById("togBtn");
        leftdiv.classList.remove("show-sidebar");
        leftdiv.classList.remove("togleftin");
        rightDiv.classList.remove("togrightin");
        togBtn.classList.remove("close-btn");
        dashdiv.classList.remove("show-sidebar");
        bodydiv.classList.remove("sidebar-body");
        
    }
    render() {
		return (
			<main className="d-flex min-vh-100 flex-column">
				<div className="dashboard d-flex min-vh-100" id="dashDiv">
                    <div className="left-sidebar" id="leftSBar" onClick={()=>this.removeHideLeft()}>
                        <LeftPane {...this.props}/>
                    </div>
                    <div className="right-sidebar d-flex flex-column" id="rightDiv">
                        <RightPane {...this.props}/>
                    </div>
                </div>
			</main>
		);
    }
}

export default Layout;