import React from 'react';
import Carousel from '../Carousel';
import HomeLessons from '../HomeLessons';
import HomeTasks from '../HomeTasks';
import HomeMessages from '../HomeMessages';
// import HomeNewMessages from '../HomeNewMessages';

// TODO HomeNewMessages component props needs to be modified
const DashBoard=(props) =>{
    const studentData = props.studentData ? props.studentData : '';
    const coursesData = props.coursesData ? props.coursesData : '';
    const advData = props.advData ? props.advData : '';
    const lessonData = props.lessonData ? props.lessonData : '';
    const messagesData = props.messagesData ? props.messagesData : '';
    const tasksData = props.tasksData ? props.tasksData : '';
    return(
        <div className="dash-content flex-grow-1">
            <h2 className="cus-heading mb-3">Welcome back <span>{studentData.firstName}!</span></h2>
            <Carousel coursesData={coursesData} advData={advData}/>
            <section className="lesnhomepge">
                <div className="row">
                    <div className="col-lg-8">
                        <HomeLessons lessonData={lessonData} lessonsCancel={props.lessonsCancel} lessonDetails={props.lessonDetails}/>
                        <HomeTasks tasksData={tasksData} tasksFileUpload={props.tasksFileUpload} tasksDetails={props.tasksDetails} tasksDataUpload={props.tasksDataUpload}/>
                    </div>
                    <div className="col-lg-4">
                        <HomeMessages messagesData={messagesData} />
                    </div>
                </div>
            </section>
        </div>
        
);
}
export default DashBoard;