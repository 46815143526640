import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import favico from '../../assets/images/favicon.png';
import home from '../../assets/images/home.png';
import calender from '../../assets/images/calander.png';
import mytask from '../../assets/images/my-task.png';
import message from '../../assets/images/message.png';
import courses from '../../assets/images/courses.png';
import profile from '../../assets/images/profile.png';
import cog from '../../assets/images/cog.png';
import help from '../../assets/images/help.png';
import logout from '../../assets/images/logout.png';

const LeftPane=(props) =>{
    var tasksArray = props.tasksData ? props.tasksData : [];
    var messagesArray = props.messagesData ? props.messagesData : [];
    var tasksCount = 0;
    var messagesCount = 0;
    if(Array.isArray(tasksArray)) {
        tasksCount = tasksArray.length;
    }
    if(Array.isArray(messagesArray)) {
        messagesCount = messagesArray.length;
    }
    var dashActive,lessonActive,taskActive,messageActive,courseAcive,ordersActive,profileActive,settingsActive,helpActive,logoutActive = 'menu-link';
    const [count, setCount] = useState(0);
    switch(count){
        case 0: {
            dashActive='menu-link active';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 1: {
            dashActive='menu-link';
            lessonActive='menu-link active';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 2: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link active';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 3: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link active';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 4: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link active';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 6: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link active';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 7: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link active';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
        case 8: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link active';
            logoutActive='menu-link';
            break;
        }
        case 9: {
            dashActive='menu-link';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link active';
            break;
        }
        default:{
            dashActive='menu-link active';
            lessonActive='menu-link';
            taskActive='menu-link';
            messageActive='menu-link';
            courseAcive='menu-link';
            ordersActive='menu-link';
            profileActive='menu-link';
            settingsActive='menu-link';
            helpActive='menu-link';
            logoutActive='menu-link';
            break;
        }
    }

    const logoutStudent=()=>{
        props.logout();
        signOut();
    }
    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    
    return(
        <div className="left-sidebar-inner">
            <div className="logo">
                <a className="logo-big d-none d-md-block"><img src={logo} alt="Jrcodebees" className="img-fluid" /></a>
                <a className="logo-mini d-md-none"><img src={favico} alt="Jrcodebees" className="img-fluid" /></a>
            </div>
            <div className="siderbar-menu">
                        <ul className="navbar-nav">
                            <li className="menu-item" onClick={() => setCount(0)}>
                                <Link to="/" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={dashActive}><img src={home} alt="home" className="img-fluid" /> <span>My Dashboard</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(1)}>
                                <Link to="/Lessons" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={lessonActive}><img src={calender} alt="calander" className="img-fluid" /> <span>Lesson Schedule</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(2)}>
                                <Link to="/Tasks" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={taskActive}><img src={mytask} alt="my-task" className="img-fluid" /> <span>Review Tasks</span><span className="badge badge-pill badge-warning">{tasksCount}</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(3)}>
                                <Link to="/Messages" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={messageActive}><img src={message} alt="Messages" className="img-fluid" /> <span>Messages</span><span className="badge badge-pill badge-warning">{messagesCount}</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(4)}>
                                <Link to="/Courses" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={courseAcive}><img src={courses} alt="my-task" className="img-fluid" /> <span>My Batches</span></div></Link>
                            </li>
                            </ul>
                            <ul className="navbar-nav nav-bottom mt-3">
                            <li className="menu-item" onClick={() => setCount(6)}>
                                <Link to="/Profile" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={profileActive}><img src={profile} alt="my-task" className="img-fluid" /> <span>My Profile</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(8)}>
                                <Link to="/HelpSupport" style={{ cursor: 'pointer', color: 'inherit', textDecoration: 'inherit'}}><div className={helpActive}><img src={help} alt="Help & Support" className="img-fluid" /> <span>Help & Support</span></div></Link>
                            </li>
                            <li className="menu-item" onClick={() => setCount(9)}>
                                <a className={logoutActive} onClick={()=>logoutStudent()}><img src={logout} alt="Logout" className="img-fluid"/> <span>Logout</span></a>
                            </li>
                        </ul>
                    </div>
        </div>
);
}
export default LeftPane;