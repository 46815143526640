import { createSelector } from 'reselect';

const coursesStudentReducer = (state) => state.coursesStudentReducer;

export const makeSelectCoursesError = () => createSelector(
  coursesStudentReducer,
  (coursesstate) => {
	return coursesstate.courseshDataError;
  }
);
export const makeSelectCoursesData = () => createSelector(
  coursesStudentReducer,
  (coursesstate) => {
	return coursesstate.coursesData;
  }
);
export const makeSelectAdvError = () => createSelector(
  coursesStudentReducer,
  (coursesstate) => {
	return coursesstate.advDataError;
  }
);
export const makeSelectAdvData = () => createSelector(
  coursesStudentReducer,
  (coursesstate) => {
	return coursesstate.advData;
  }
);
export const makeSelectStudentCoursesStatusError = () => createSelector(
  coursesStudentReducer,
  (coursesstate) => {
	  return coursesstate.coursesStatusError;
  }
);
export const makeSelectStudentCoursesStatusData = () => createSelector(
  coursesStudentReducer,
  (coursesstate) => {
	  return coursesstate.coursesStatus;
  }
);
export const makeSelectCourseDetailsError = () => createSelector(
  coursesStudentReducer,
  (coursesstate) => {
	  return coursesstate.coursesDetailsError;
  }
);
export const makeSelectCourseDetails = () => createSelector(
  coursesStudentReducer,
  (coursesstate) => {
	  return coursesstate.coursesDetails;
  }
);