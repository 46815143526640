import React, { Component } from 'react';
import psdmg from '../../assets/images/detailed_page_no_image.PNG';
import NoData from '../NoData';
import AttendanceModal from '../../containers/Attendance';


export const CourseDetailsItems = (props)=>{
   var displayTime = '';
   const classDesc = props.courses ? props.courses.classDesc : '';
   const classIcon = props.courses ? props.courses.classIcon : '';
   const lessonName = props.courses ? props.courses.lessonName : '';
   const startTime = props.courses ? props.courses.startTime : '';
   const classId = props.courses ? props.courses.classId : '';
   const classIndex = props.courses ? props.courses.classIndex : '';
   const timeFormat = new Date(startTime);
   const showModal=()=>{
      const modalDiv = document.getElementById('atn');
      modalDiv.style.display="block";
      props.setAttendance(classIndex,lessonName,classIcon)
   }
   
   if(startTime) {
        displayTime=timeFormat.toLocaleString('en-US',{dateStyle: 'medium', timeStyle: 'short'});
    }
   return(
      <div class="col-md-6 col-lg-6 col-xl-3 col-12">
      <div class="card card-theme mb-4">
         <div class="card-header flex-column ratingflex">
            <div class="course d-flex align-items-center ratingtb">
               <img src={classIcon || psdmg } alt="photoshop" class="img-fluid mr-2 rounded-circle"/>
               <p class="mb-0">{lessonName}</p>
            </div>
            <div class="course d-flex align-items-center">
               <p class="ratings titbtch">Class {classIndex}</p>
            </div>
         </div>
         <div class="card-body">
            <div class="lesson-inner">
                  <div class="lesson-heading">{classDesc}</div>
            </div>
            <div class="row icns">
               <div class="col-md-6 col-lg-6 brdr col-6">
                  <p><i class="fa fa-spinner"></i></p>
                  <p>In Progress</p>
               </div>
               <div class="col-md-6 col-lg-6 col-6">
                  <p><i class="fa fa-calendar-alt"></i></p>
                  <p class="activedate">{displayTime}</p>
               </div>
            </div>
            <div class="card-foot">
               <button type="button" class="btn btn-theme-light btn-rounded opnatndnce" onClick={()=>showModal()}><i class="fa fa-user-friends"></i> Students</button>
            </div>
         </div>
      </div>
   </div>
   );
}


class CourseDetails extends Component {
    constructor(props) {
      super(props);
      this.setAttendance = this.setAttendance.bind(this);
      this.state = {
         classIndex:'',
         batch:'',
         classIcon:''
       };
    }

   setAttendance(classId,batchId,classIcon){
      this.setState({classIndex:classId});
      this.setState({batch:batchId});
      this.setState({classIcon:classIcon});
    }

render() {
    const courseArray = this.props.courseDetails ? this.props.courseDetails : [];
    const noOfCourses = this.props.courseDetails ? this.props.courseDetails.length : 0;
    return(
      <>
      <AttendanceModal classIndex={this.state.classIndex} batch={this.state.batch} classIcon={this.state.classIcon}/>
      <div class="dash-content flex-grow-1 coursetxtpge">
      <div class="row">
          <div class="col-md-8 col-7">
              <a href="mybatches.html" class="btchss"><h2 class="allessn"> <i class="fa fa-arrow-left" aria-hidden="true"></i> All Batches </h2></a>
              <h2 class="cus-heading mb-3">Batch Detail Index</h2>
          </div>
          <div class="col-md-4 col-5 filtrcol">
              <a href="calender.html"><button class="btn btn-theme-light btn-rounded filters cldrbtn"><i class="fa fa-calendar-alt"></i> Training Calendar</button></a>
          </div>
      </div>
     <section class="sliders mb-sm-4">
        <div class="row">
           <div class="col-lg-12">
              <ul class="nav nav-tabs">
                 <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#upcoming">Active Batches &nbsp;&nbsp;<span class="badge badge-pill badge-warning">{noOfCourses}</span></a>
                 </li>
                 <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#notscheduled">Completed</a>
                 </li>
              </ul>
              <div class="tab-content mycrses">
                 <div class="tab-pane  active" id="upcoming">
                    <div class="card card-theme mb-4 nmecard">
                       <div class="card-body">
                          <div class="cuslessons btch">
                              <div class="row">
                                 {
                                    courseArray.length > 0 ?
                                       (courseArray.map((courseData, index) => (
                                          <CourseDetailsItems index={index} courses={courseData} key={index} setAttendance={this.setAttendance}/>             
                                       )))
                                          :
                                       <NoData apiCalled={this.props.coursesStatus}/>                     
                                 }
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
  </div>
  </>
);
}
}
export default CourseDetails;